import { injectStyle } from "@nrk/origo";
import Fallback from "./components/Fallback.svelte";
import Plugin from "./components/Plugin/Plugin.svelte";
import Standalone from "./components/Standalone.svelte";
import { getBildebankenHost, getMountElement, getRelease, isEmbedded } from "./config";
import "./defineCustomElements.js";
import { getOptions, initMessageChannel, setPluginOptions } from "./services/pluginMessages";
import { getEnvironmentFromHostname } from "utils/fns";

let Sentry;

if (getRelease() !== "dev") {
  import("@sentry/svelte").then((sentry) => {
    Sentry = sentry;
    Sentry.init({
      dsn: "https://0c1305aa0fa11f1b8f2b40f49858fcb5@o124059.ingest.sentry.io/4506268959440896",
      integrations: [new Sentry.BrowserTracing()],
      //Performance Monitoring
      tracesSampleRate: 1.0,
      release: getRelease(),
      environment: getEnvironmentFromHostname(getBildebankenHost().hostname),
    });
  });
}

injectStyle();

const mountEl = getMountElement();

if (mountEl) {
  if (isEmbedded()) {
    initMessageChannel()
      .then(async () => {
        const options = await getOptions();
        setPluginOptions(options);
        new Plugin({ target: mountEl });
      })
      .catch((reason) => {
        Sentry?.captureException(reason);
        console.warn("Plugin could not establish host communication", reason);
        new Fallback({
          target: mountEl,
          props: { message: "Kunne ikke etablere kommunikasjon med innbyggende tjeneste.", reason },
        });
      });
  } else {
    new Standalone({ target: mountEl });
  }
} else {
  console.error("Could not find element to mount BB to.");
}
