<script lang="ts">
  import { fade } from "svelte/transition";
  import {
    cancelUpload,
    cleanupAndResetUploadState,
    getFailedUploads,
    getPlaceholderProgress,
    getUploadItemIds,
    uploadFiles,
    uploads,
  } from "state/upload";
  import { returnToSearchPage } from "state/page";
  import Edit from "../Editor/Edit.svelte";
  import Progress from "../Common/Progress.svelte";
  import { orgWarn } from "@nrk/origo";
  import { onMount } from "svelte";
  import { getRequiredFields, type RequiredFieldKey } from "services/plugin-customization";
  import type { EditableFieldKey } from "components/Editor/editImageMachine";
  import { getSelectImageOnSave } from "../../services/plugin-customization.js";
  import {SUPPORTED_MIME_TYPES} from "bildebanken-model";

  //These fields are always required.
  const requiredFields: EditableFieldKey[] = ["title", "rights"];
  //These fields are optionally added by plugin users.
  let userDefinedRequiredFields: RequiredFieldKey[];

  const supportedMimeTypes = SUPPORTED_MIME_TYPES.filter(f => f!=="image/*").map(f => f.split("/")[1]).join(", ")
  const failedFiles =
    ($uploads.state === "ALL_FAILED" &&
      Object.entries($uploads.files)
        .map(([filename, value]) => {
          if (value.state === "FAILED" && value.error.startsWith("Kan ikke autentisere brukeren")) {
            return value.file;
          }
        })
        .filter(Boolean)) ||
    [];

  if (failedFiles.length > 0) {
    //@ts-ignore - Convince svelte-check that files is of type File[]
    cancelUpload().then(() => uploadFiles(failedFiles));
  }

  $: uploadProgress = $uploads.state !== "IDLE" ? $uploads.progress : 0;
  $: progressStatus =
    $uploads.state === "ALL_UPLOADED"
      ? ("SUCCESS" as const)
      : $uploads.state === "ALL_FAILED"
      ? ("FAILED" as const)
      : ("IN_PROGRESS" as const);

  function mergeRequiredFields(
    requiredFields: EditableFieldKey[],
    userDefinedRequiredFields: RequiredFieldKey[],
  ): EditableFieldKey[] {
    if (userDefinedRequiredFields)
      return [...requiredFields, ...(userDefinedRequiredFields as EditableFieldKey[])];
    return requiredFields;
  }

  async function onCancel() {
    if (confirm("Hvis du avbryter vil opplastingene slettes")) {
      await cancelUpload();
      returnToSearchPage();
    }
  }

  async function onDone() {
    // Temporary fix for issue where the user is not returned to the search page after uploading multiple files
    const multipleFilesUploaded =
      $uploads.state !== "IDLE" && Object.keys($uploads.files).length > 1;
    await cleanupAndResetUploadState();
    if (!getSelectImageOnSave() || multipleFilesUploaded) {
      returnToSearchPage();
    }
  }

  onMount(() => {
    userDefinedRequiredFields = (getRequiredFields() as RequiredFieldKey[]) || [];
  });

  const beforeUnload = (event) => {
    if ($uploads.state === "IN_PROGRESS" || $uploads.state === "ALL_UPLOADED") {
      // Setting the returnValue is what enables the 'Unsaved changes' prompt
      event.returnValue = "";
    }
  };
</script>

<svelte:window on:beforeunload|preventDefault={beforeUnload} />
{#if $uploads.state === "IN_PROGRESS" || $uploads.state === "SOME_FAILED" || $uploads.state === "ALL_UPLOADED" || $uploads.state === "IDLE"}
  <div class="edit-upload" in:fade|local={{ delay: 0, duration: 100 }}>
    <Progress
      value={uploadProgress}
      status={progressStatus}
      id="upload-progress"
      srLabel="Opplasting"
    />
    {#if $uploads.state === "SOME_FAILED"}
      <div class="org-warning">Noen opplastinger feilet</div>
    {/if}
    <Edit
      itemIds={getUploadItemIds($uploads)}
      requiredFields={mergeRequiredFields(requiredFields, userDefinedRequiredFields)}
      {onCancel}
      {onDone}
    />
  </div>
{:else}
  <div class="wrapper" in:fade|local={{ delay: 100, duration: 100 }}>
    {#if $uploads.state === "PREPARING"}
      {@const progress = getPlaceholderProgress($uploads)}
      {@const failed = getFailedUploads($uploads)}
      <h2>Forbereder opplasting av {progress[1]} {progress[1] > 1 ? "filer" : "fil"}</h2>
      <!-- svelte-ignore a11y-label-has-associated-control -->
      <label class="progress-wrapper">
        <bb-progress
          type="radial"
          value={progress[0] / progress[1]}
          error={failed.length === progress[1] ? true : failed.length > 0 ? "partial-error" : false}
          style="width: 50px; background-color: transparent"
        />
        <span>
          {progress[0]} / {progress[1]}
          {#if failed.length > 0}
            ({failed.length} feilet)
          {/if}
        </span>
      </label>
    {:else if $uploads.state === "CANCELLING"}
      <h2>Rydder opp etter avbrutt opplasting</h2>
      <div class="org-spinner" />
    {:else if $uploads.state === "CLEANING_UP"}
      <h2>Sletter feilede opplastinger</h2>
      <div class="org-spinner" />
    {:else if $uploads.state === "ALL_FAILED"}
      <h2>
        {@html orgWarn}
        {Object.keys($uploads.files).length > 1 ? "Alle opplastinger" : "Opplasting"} feilet
      </h2>
      <p>
        Støttede filtyper er {supportedMimeTypes}
      </p>
      <button class="org-button org-button--primary" on:click={onDone}>Lukk</button>
    {/if}
  </div>
{/if}

<style>
  .wrapper {
    padding: var(--org-medium);
    height: 100%;
  }

  .org-spinner {
    padding: var(--org-large);
  }

  .edit-upload {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .org-warning {
    border-radius: 0;
    flex-shrink: 0;
  }

  .progress-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--org-medium);
    width: 250px;
    height: 75px;
    /*background: var(--org-color-shade-1);*/
    padding: var(--org-small);
  }
</style>
