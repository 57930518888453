import { Mimir, type RightsMarker } from "bildebanken-model";
import { isPlainObject } from "lodash-es";

export const PAGE_SIZE = 60;
export type DateField = "mediaCreatedOn" | "createdOn";

export function isDateField(value?: string | null): value is DateField {
  return (!!value && value === "mediaCreatedOn") || value === "createdOn";
}

export const defaultDateField = "mediaCreatedOn";
export type DateFilter = {
  field: DateField;
  label: string;
} & (
  | {
      type: "Since";
      secondsBack: number;
    }
  | {
      type: "Range";
      from?: Date;
      to?: Date;
    }
);
export type PhotographerFilter = {
  name: string;
  id?: string;
};
export const availableSourceSystems = ["Bildebanken", "Feltleveranse", "Stills"] as const;
export type SourceSystem = (typeof availableSourceSystems)[number];

export function isValidSourceSystem(system: string): system is SourceSystem {
  return availableSourceSystems.some((v) => v === system);
}

export type SearchFilter = {
  date?: DateFilter;
  rightsMarker?: RightsMarker;
  sourceSystems?: SourceSystem[];
  photographer?: PhotographerFilter;
  jobId?: string;
  owner?: "current-user";
  folderId?: string;
};

export type SortParams = {
  sortBy: "mediaCreatedOn" | "createdOn" | "modifiedOn";
  sortOrder: Mimir.SortOrder;
};

export type SearchParams = {
  query: string;
  owner?: "current-user";
  folderId?: string;
  filter: SearchFilter;
  sort: SortParams;
  from: number;
};

export type ExternalSearchParams = Partial<Pick<SearchParams, "filter" | "query">>;

export const defaultSearchParams: SearchParams = {
  query: "",
  owner: undefined,
  filter: {
    sourceSystems: [],
    date: undefined,
    rightsMarker: undefined,
    photographer: undefined,
    jobId: undefined,
  },
  sort: {
    sortBy: "mediaCreatedOn",
    sortOrder: "desc",
  },
  from: 0,
};

// wrap the lodash function to get type assertion
function isObject(obj: unknown): obj is object {
  return isPlainObject(obj);
}

export type PluginSearchParams = {
  query?: string;
  rightsMarker?: "Free";
  owner?: "current-user";
};

function validatePluginParams(params: unknown) {
  if (!isObject(params)) {
    throw new RangeError("Plugin search params is not an object");
  }

  if ("query" in params && typeof params.query !== "string") {
    throw new RangeError("Plugin search query must be string");
  }

  if ("rightsMarker" in params && params.rightsMarker !== "Free") {
    throw new RangeError("Invalid rights-marker in plugin search params");
  }

  if ("owner" in params && params.owner !== "current-user") {
    throw new RangeError("Invalid owner in plugin search params");
  }

  params satisfies PluginSearchParams;
  return true;
}

export function mapPluginSearchParams(input?: PluginSearchParams): SearchParams {
  if (!input) return defaultSearchParams;

  validatePluginParams(input);

  const params = { ...defaultSearchParams, filter: { ...defaultSearchParams.filter } };

  if (input.query) {
    params.query = input.query;
  }

  if (input.rightsMarker) {
    params.filter.rightsMarker = input.rightsMarker;
  }

  if (input.owner) {
    params.owner = input.owner;
  }

  return params;
}
