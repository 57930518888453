<script lang="ts">
  import { orgGlobe, orgUser } from "@nrk/origo";
  import { Mimir } from "bildebanken-model";
  import { user } from "services/api";
  import { getDetailLink } from "state/page";
  import { createMetadataQuery } from "state/queries/mimir";
  import { getThumbnailUrl } from "state/thumbnails";
  import { onMount } from "svelte";

  export let item: Mimir.SearchResultItem;
  export let disabled = false;

  const title = item ? Mimir.getTitle(item) : "<ukjent>";
  const detailPath = getDetailLink(item.id);

  $: query = createMetadataQuery(item.id);
  $: result = $query;
  $: published = result.isSuccess ? result.data?.metadata?.publicId !== undefined : undefined;

  let owner: Mimir.User | undefined = undefined;

  onMount(async () => {
    if (disabled) {
      owner = await user(item.createdBy);
    }
  });
</script>

<li class="wrapper" data-testid="item-to-be-deleted" class:disabled>
  <div>
    <a href={detailPath} target="_blank" rel="noreferrer">
      <img src={getThumbnailUrl(item)} alt="" /><span>{title}</span>
    </a>
  </div>

  {#if published}
    <a href={detailPath} target="_blank" rel="noreferrer" class="published">
      {@html orgGlobe} Publisert
    </a>
  {:else if disabled && owner}
    <span class="owner">
      {@html orgUser}
      <a class="name-link" href="mailto:{owner.email}">{owner.givenName} {owner.familyName}</a>
    </span>
  {/if}
  <slot />
</li>

<style>
  li {
    list-style: none;
    margin-bottom: var(--org-small);
    display: flex;
    gap: var(--org-small);
    align-items: center;
    justify-content: space-between;
  }

  a:hover {
    text-decoration: underline;
    filter: brightness(1.07);
  }

  img {
    display: inline;
    width: 60px;
    height: 40px;
    object-fit: cover;
    margin-right: var(--org-small);
  }

  .disabled {
    opacity: 0.5;
  }

  .published :global(svg) {
    font-size: 0.75rem;
    margin-bottom: 2px;
  }

  .owner :global(svg) {
    font-size: 0.65rem;
    margin-bottom: 4px;
  }
</style>
