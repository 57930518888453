<svelte:options immutable />

<script lang="ts">
  import ImagePreview from "components/Common/ImagePreview.svelte";
  import RightsIndicator from "components/Common/RightsIndicator.svelte";
  import Timestamp from "components/Common/Timestamp.svelte";
  import type { SearchHit } from "services/searchHit";
  import { readable, type Readable } from "svelte/store";
  import { hasImageCreatedDate } from "utils/fns";
  import { preload } from "../preload";
  import type { SearchHitHandler } from "../searchHitHandler";
  import SearchHitSrSelectionCheckbox from "../SearchHitSrSelectionCheckbox.svelte";
  import { dragImageStart } from "services/folders/func";

  export let searchHit: SearchHit;
  export let handler: SearchHitHandler;
  export let selected: Readable<boolean> = readable(false);
  export let selectable = false;
  export let preloader = () => Promise.resolve();

  let focus = false;

  $: timestamp =
    searchHit.state === "complete" ? searchHit.mediaCreatedOn : searchHit.itemCreatedOn;

  $: imageCreatedDateAvailable = hasImageCreatedDate(searchHit);
  $: timeZone = imageCreatedDateAvailable ? "UTC" : undefined;
</script>

<tr
  class:focus
  on:click={handler.onClickSearchHit}
  on:dblclick={handler.onDblClickSearchHit}
  on:keydown
  use:preload={preloader}
  on:dragstart={(event) => {
    dragImageStart(event, searchHit);
  }}
  id={searchHit.id}
  data-selected={$selected}
  on:mousedown={(event) => {
    if (event.shiftKey) {
      document.getSelection()?.removeAllRanges();
    }
  }}
>
  <td>
    {#if selectable}
      <SearchHitSrSelectionCheckbox
        {handler}
        {searchHit}
        selected={$selected}
        {preloader}
        bind:focus
      />
    {/if}
    <div class="table-image">
      <ImagePreview mode="table" image={searchHit} />
    </div>
  </td>
  <td style="max-width: 200px">
    {#if searchHit.detailPageUrl}
      <a href={searchHit.detailPageUrl} class="title overflow-ellipsis">
        {searchHit.metadata.title}
      </a>
    {:else}
      <span class="title overflow-ellipsis">{searchHit.metadata.title}</span>
    {/if}
  </td>
  <td style="max-width: 200px">
    <span class="overflow-ellipsis">{searchHit.metadata.description ?? ""}</span>
  </td>
  <td class="for-desktop">{searchHit.metadata.creators?.join(", ") || ""}</td>
  <td class="right timestamp">
    <Timestamp
      createdDateAvailable={imageCreatedDateAvailable || false}
      date={timestamp}
      {timeZone}
    />
  </td>
  <td class="for-desktop">
    <span style="display: inline-block; height: 23px">
      <RightsIndicator rightsMarker={searchHit.metadata.rightsMarker} />
      {searchHit.metadata.copyrightOwners?.filter((owner) => owner !== "NRK").join(", ") || ""}
    </span>
  </td>
</tr>

<style>
  tr {
    scroll-margin: 100px;
    border: 2px solid transparent;
    filter: none;
  }

  .title {
    font-weight: bold;

    /* overflow-ellipsis is display: block, but that causes the whole table cell to be an anchor tag */
    display: inline-block;
    vertical-align: middle;
  }

  tr.focus {
    box-shadow: inset var(--org-shadow-focus);
  }

  tr:hover {
    background-color: var(--org-color-shade-1);
  }

  td.right {
    text-align: right;
  }

  td.timestamp {
    min-width: 18ch;
  }

  td:first-child {
    padding: var(--org-xsmall);
  }

  [data-selected="true"],
  tr.focus[data-selected="true"],
  tr:hover[data-selected="true"] {
    background: var(--bb-selected-bg-color);
    color: var(--org-white);
  }

  tr:hover[data-selected="true"]:hover {
    background-color: var(--bb-selected-hover-bg-color);
  }

  .for-desktop {
    display: table-cell;
  }

  .table-image {
    display: flex;
    justify-content: center;
    height: calc(24px * var(--scale));
    max-width: calc(36px * var(--scale));
    width: calc(36px * var(--scale));
  }

  @media screen and (max-width: 1024px) {
    .for-desktop {
      display: none;
    }
  }

  /* Safari hacks */
  @media screen and (min-color-index: 0) and (-webkit-min-device-pixel-ratio: 0) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      /* No reliable way to add an outline/box-shadow to a table cell in Safari */
      tr.focus {
        background-color: var(--org-color-shade-2);
      }
      tr.focus:hover {
        background-color: var(--org-color-shade-3);
      }

      tr.focus[data-selected="true"] {
        background-color: var(--bb-selected-hover-bg-color);
      }

      .focus img,
      :hover img {
        outline: 2px solid var(--org-color-focus);
        outline-offset: 2px;
      }
    }
  }
</style>
