<script lang="ts">
  import { orgClose } from "@nrk/origo";
  import { isEqual, nth } from "lodash-es";
  import { searchFilter, updateSearchFilter } from "state/page";
  import {
    availableSourceSystems,
    type DateFilter,
    defaultSearchParams,
    type SearchFilter,
  } from "state/params";
  import { getFolderAncestry, rootFolderId } from "../../services/folders/api";
  import { Mimir } from "bildebanken-model";
  import { rootFolderIds } from "components/Folders/folders";
  import { format } from "date-fns";
  import { nb } from "date-fns/locale";

  type FilterKey = keyof SearchFilter;
  const listFormat = new Intl.ListFormat("nb", { style: "long", type: "conjunction" });

  $: pills = buildPills($searchFilter);

  type Pill = {
    key: FilterKey;
    label: string;
  };

  function mapDatePill(value: DateFilter) {
    const secondsBackMap: { [key: number]: string } = {
      3600: "timen",
      14400: "4 timer",
      86400: "24 timer",
      604800: "uken",
      2678400: "måneden",
    };
    let datePill;
    if (value.type === "Since") {
      datePill = secondsBackMap[value.secondsBack]
        ? value.label.replace(`${value.secondsBack}`, secondsBackMap[value.secondsBack])
        : value.label;
    } else {
      datePill = `fra ${value.from?.toLocaleDateString("no-NB")} til ${value.to?.toLocaleDateString(
        "no-NB",
      )}`;
    }
    if (value.field === "mediaCreatedOn" && !datePill.includes("Fotografert"))
      return `Fotografert ${datePill}`;
    else if (value.field === "createdOn" && !datePill.includes("Lastet opp"))
      return `Lastet opp ${datePill}`;
    else return datePill;
  }

  function isDateFilter(filter: DateFilter | any): filter is DateFilter {
    return filter.label && filter.field && (filter.type === "Range" || filter.type === "Since");
  }

  function buildPills(filter: SearchFilter) {
    return Object.entries(filter)
      .map(([key, value]) => mapPill(key as FilterKey, value))
      .filter(Boolean) as Pill[];
  }

  async function buildFolderPill(filter: SearchFilter) {
    return (await Object.entries(filter)
      .filter(([key]) => key === "folderId")
      .map(
        async ([key, value]) => await mapFolderPill(key as FilterKey, value),
      )[0]) as unknown as Pill;
  }

  function createFolderBreadCrumb(ancestry: Mimir.AncestryResponse, folderId: string) {
    const folders = ancestry[folderId].paths[folderId].pop();

    const productionSubfolder = folders?.findIndex(
      (folder) => folder.id === rootFolderIds.stage.productions,
    );

    if (productionSubfolder) {
      const production = nth(folders, -1)?.name;
      const parent = nth(folders, -2);
      const formattedDate =
        (parent && format(new Date(parent.name), "d. MMMM", { locale: nb })) || "";

      return `${production} ${formattedDate}`;
    }
    return folders
      ?.map((folder) => folder.name)
      ?.splice(3, 4)
      .join(" / ");
  }

  async function mapFolderPill<K extends FilterKey>(key: K, value: SearchFilter[K]) {
    const folderAncestry = await getFolderAncestry(`${value}`);
    const label = createFolderBreadCrumb(folderAncestry, value?.toString() || "");
    const k = "folderId";
    return { k, label };
  }

  function mapPill<K extends FilterKey>(key: K, value: SearchFilter[K]): Pill | undefined {
    if (key === "rightsMarker") {
      if (value === "Free") {
        return { key, label: "Fri gjenbruk i NRK" };
      } else {
        return;
      }
    }

    if (key === "photographer") {
      if (typeof value === "object" && "name" in value) {
        return { key, label: "Fotograf: " + value.name };
      }
    }

    if (key === "date") {
      if (typeof value === "object" && isDateFilter(value)) {
        return { key, label: mapDatePill(value) };
      }
    }

    if (
      key === "sourceSystems" &&
      !isEqual(value, defaultSearchParams.filter.sourceSystems) &&
      Array.isArray(value)
    ) {
      if (value.length > 0 && value.length < availableSourceSystems.length) {
        return { key, label: "System: " + listFormat.format(value) };
      } else {
        return { key, label: "Alle systemer" };
      }
    }

    if (key === "jobId") {
      if (value) {
        return { key, label: "Søker på relaterte bilder" };
      }
    }

    // TODO: Generate labels for date filters here too
    if (typeof value === "object" && "label" in value) {
      return {
        key,
        label: value.label,
      };
    }
  }

  function removePill(key: keyof SearchFilter) {
    if (key === "sourceSystems") {
      updateSearchFilter(key, defaultSearchParams.filter.sourceSystems);
    } else {
      updateSearchFilter(key);
    }
  }

  $: folderId = Object.entries($searchFilter)
    .filter(([key, value]) => key === "folderId")
    .map(([key, value]) => value)[0] as string;
</script>

<div class="search-pills">
  {#each pills as pill (pill.key)}
    <button type="button" on:click={() => removePill(pill.key)} class="org-tag">
      {pill.label}
      {@html orgClose}
    </button>
  {/each}
  {#if folderId}
    {#await buildFolderPill($searchFilter) then folderPill}
      <button type="button" on:click={() => removePill("folderId")} class="org-tag">
        {folderPill.label}
        {@html orgClose}
      </button>
    {/await}
  {/if}
</div>

<style>
  .search-pills {
    margin-right: 5px;
    display: flex;
  }
  button {
    display: flex;
    align-items: center;
    gap: var(--org-small);
  }
</style>
