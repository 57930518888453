export type RequiredFieldKey = "description" | "altText" | "imageType";

export type UploadOptions = {
  requiredFields?: RequiredFieldKey[];
  selectImageOnSave?: boolean;
};

const uploadOptions: UploadOptions = {};
let system: string | undefined;

export function setUploadOptions(options: UploadOptions) {
  uploadOptions.requiredFields = options.requiredFields || [];
  uploadOptions.selectImageOnSave = options.selectImageOnSave;
  if (uploadOptions.selectImageOnSave === undefined) {
    uploadOptions.selectImageOnSave = true;
  }
}

export function getRequiredFields(): RequiredFieldKey[] | undefined {
  return uploadOptions.requiredFields;
}

export function getSelectImageOnSave(): boolean | undefined {
  return uploadOptions.selectImageOnSave;
}

export function getSystem(): string | undefined {
  return system;
}

export function setSystem(systemName: string) {
  system = systemName;
}
