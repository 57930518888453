<script lang="ts">
  import { nrkChevronLeft } from "@nrk/core-icons";
  import { onMount } from "svelte";

  import { isEmbedded } from "config";
  import SelectionService from "state/SelectionService";
  import {
    isMyImagesPage,
    pageState,
    searchParams,
    setActiveSearchSource,
    setSearchScrollPosition,
    showDetails,
  } from "state/page";
  import { getCountFromQuery } from "state/queries";
  import { getKaleidoSearchHits, getKaleidoSearchQuery } from "state/queries/kaleido";
  import { createPreloader, getMimirSearchHits, getMimirSearchQuery } from "state/queries/mimir";
  import { showCount } from "utils/fns";

  import { upload } from "components/Upload/upload";
  import { debounce } from "lodash-es";
  import ResultsWrapper from "./ResultsWrapper.svelte";
  import SearchIndicator from "./SearchIndicator.svelte";
  import ViewSettings from "./ViewSettings.svelte";
  import { itemOnKeydown } from "./keyboardInteraction";
  import {
    createBildebankenSearchHitHandler,
    createKaleidoSearchHitHandler,
    createNtbSearchHitHandler,
  } from "./searchHitHandler";
  import { getNtbSearchHits, getNtbSearchQuery } from "state/queries/ntb";
  import { useFeatureToggle } from "services/configCatService";

  let bbTabs: HTMLElement & {
    get tab(): HTMLElement;
    set tab(tab: number | string);
    tabs: any;
    panel: any;
    panels: any;
  };
  let wrapperEl: HTMLElement;

  const mimirQuery = getMimirSearchQuery();
  const kaleidoQuery = getKaleidoSearchQuery();
  const ntbQuery = getNtbSearchQuery();
  const mimirSearchHits = getMimirSearchHits();
  const kaleidoSearchHits = getKaleidoSearchHits();
  const ntbSearchHits = getNtbSearchHits();
  const mimirCount = getCountFromQuery(mimirQuery);
  const kaleidoCount = getCountFromQuery(kaleidoQuery);
  const ntbCount = getCountFromQuery(ntbQuery);

  $: page = $pageState;
  $: myImages = isMyImagesPage(page);

  let dragOver = false;
  const embedded = isEmbedded();

  function selectActiveTab(
    mimirCount: number,
    kaleidoCount: number,
    ntbCount: number,
    mq: typeof $mimirQuery,
  ) {
    if (bbTabs) {
      if (
        mq.isFetchingNextPage ||
        $kaleidoQuery.isFetchingNextPage ||
        $ntbQuery.isFetchingNextPage ||
        mq.isFetching
      ) {
        return;
      }
      // Optimistically try to switch to Mimir tab (i.e. if we have cached hits, switch immediately)
      if (mimirCount > 0) {
        bbTabs.tab = "bildebanken-tab";
      } else {
        bbTabs.tab =
          kaleidoCount > 0 ? "kaleido-tab" : ntbCount > 0 ? "ntb-tab" : "bildebanken-tab";
      }
    }
  }

  $: selectActiveTab($mimirCount, $kaleidoCount, $ntbCount, $mimirQuery);

  $: SelectionService.send({
    type: "UPDATE_ITEMS",
    itemIds: $mimirSearchHits.map((item) => item.id) ?? [],
  });

  // Focus first image selector on arrow right
  function focusOnArrowRight(event: KeyboardEvent & { target: Element }) {
    if (event.code !== "ArrowRight") return;
    if (event.target.nodeName === "INPUT" || event.target.nodeName === "TEXTAREA") return;

    document.querySelector<HTMLInputElement>("input[name=select-image]")?.focus();
  }

  onMount(() => {
    document.addEventListener("keydown", focusOnArrowRight);
    document.addEventListener("tabs.toggle", tabsToggled);
    setTimeout(() => {
      switchTabToPreviousSearchResultsTab();
      scrollToPreviousScrollState();
    }, 0);
    return () => {
      document.removeEventListener("keydown", focusOnArrowRight);
      document.removeEventListener("tabs.toggle", tabsToggled);
    };
  });

  function scrollToPreviousScrollState() {
    if (wrapperEl && "searchScrollPosition" in $pageState) {
      wrapperEl.scrollTo({ top: $pageState.searchScrollPosition || 0 });
    }
  }

  function switchTabToPreviousSearchResultsTab() {
    if (bbTabs && "activeSearchSource" in $pageState) {
      switch ($pageState.activeSearchSource) {
        case "Bildebanken":
          bbTabs.tab = "bildebanken-tab";
          break;
        case "Kaleido":
          bbTabs.tab = "kaleido-tab";
          break;
        case "Ntb":
          bbTabs.tab = "ntb-tab";
      }
    }
  }

  const updateScroll = debounce(setSearchScrollPosition, 100);

  function tabsToggled(event) {
    let currentTab;
    if (bbTabs && bbTabs.tab.id === "kaleido-tab") {
      currentTab = "Kaleido";
    } else if (bbTabs && bbTabs.tab.id === "ntb-tab") {
      currentTab = "Ntb";
    } else {
      currentTab = "Bildebanken";
    }
    setActiveSearchSource(currentTab);
  }

  function onScroll(this: HTMLElement) {
    updateScroll(this.scrollTop);
  }
</script>

<!-- svelte-ignore a11y-no-static-element-interactions-->
<div
  class="wrapper"
  data-testid="search-results"
  bind:this={wrapperEl}
  class:dragOver
  on:dragover|preventDefault={(event) => {
    if (event.dataTransfer) {
      event.dataTransfer.effectAllowed = "copy";
      event.dataTransfer.dropEffect = "copy";
    }
    dragOver = true;
  }}
  on:dragleave|preventDefault={(_) => {
    dragOver = false;
  }}
  on:drop|preventDefault={(event) => {
    dragOver = false;
    if (event.dataTransfer) {
      upload(event.dataTransfer.files);
    }
  }}
  on:scroll={onScroll}
>
  <h1 class="screen-reader-only">Søkeresultater</h1>
  {#if embedded}
    <div class="top-bar">
      <div style="display: flex; flex-direction: row; gap: 5px">
        <a class="org-button" style="padding: 0; margin: 0" href="/" title="Tilbake">
          {@html nrkChevronLeft}
        </a>
        <SearchIndicator />
      </div>
      <ViewSettings />
    </div>
  {/if}
  {#if !myImages}
    <bb-tabs bind:this={bbTabs}>
      <button id="bildebanken-tab">
        <span class="tab-button-label">Bildebanken</span>
        <span class="counter-wrapper">
          <span class="counter">
            {#if $mimirQuery.isSuccess}
              {showCount($mimirCount || 0)}
            {:else}
              …
            {/if}
          </span>
        </span>
      </button>
      {#if !$searchParams.filter.folderId}
        <button id="kaleido-tab">
          <span class="tab-button-label">Arkiv (Kaleido)</span>
          <span class="counter-wrapper">
            <span class="counter">
              {#if $kaleidoQuery.isSuccess}
                {showCount($kaleidoCount || 0)}
              {:else if $kaleidoQuery.isError}
                {0}
              {:else}
                …
              {/if}
            </span>
            <span class="counter-wrapper"> </span></span
          ></button
        >
        {#await useFeatureToggle("ntbSearch") then featureEnabled}
          {#if featureEnabled}
            <button id="ntb-tab">
              <span class="tab-button-label">NTB</span>
              <span class="counter-wrapper">
                <span class="counter">
                  {#if $ntbQuery.isSuccess}
                    {showCount($ntbCount || 0)}
                  {:else}
                    …
                  {/if}
                </span>
                <span class="counter-wrapper"> </span></span
              ></button
            >
          {/if}
        {/await}
      {/if}
    </bb-tabs>
  {/if}

  <div class="tab" id="bildebanken" data-testid="bildebanken-search-results">
    <ResultsWrapper
      query={mimirQuery}
      searchHits={$mimirSearchHits}
      handler={createBildebankenSearchHitHandler({
        embedded,
        selectionService: SelectionService,
      })}
      onKeyDown={itemOnKeydown({
        selectionService: SelectionService,
        openDetailsForItem: showDetails,
      })}
      {createPreloader}
    />
  </div>
  <div class="tab" id="kaleido" data-testid="kaleido-search-results" hidden>
    <ResultsWrapper
      query={kaleidoQuery}
      searchHits={$kaleidoSearchHits}
      handler={createKaleidoSearchHitHandler({ embedded })}
    />
  </div>
  <div class="tab" id="ntb" data-testid="ntb-search-results" hidden>
    <ResultsWrapper
      query={ntbQuery}
      searchHits={$ntbSearchHits}
      handler={createNtbSearchHitHandler({ embedded })}
    />
  </div>
</div>

<style>
  .screen-reader-only {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    position: absolute;
    white-space: nowrap;
    overflow: hidden;
    width: 1px;
  }

  button {
    color: var(--color-text-secondary);
    width: 12rem;
  }

  bb-tabs,
  .tab {
    background: transparent;
  }

  bb-tabs button {
    font-weight: normal;
    gap: var(--org-xsmall);
  }

  .tab-button-label {
    display: inline-block;
    margin-right: var(--org-xsmall);
  }

  .counter {
    background: var(--color-surface-invert);
    border-radius: 5px;
    padding: 2px 5px;
    font-size: 10px;
    color: var(--color-text-invert);
    min-width: 4ch;
    height: 2em;
    display: inline-block;
    text-align: center;
    line-height: 1.6;
  }

  .counter-wrapper {
    display: inline-block;
    text-align: left;
    width: 6ch;
  }

  .wrapper {
    flex: 1;
    max-height: 100%;
    overflow: auto;
    border-radius: 5px;
    padding: var(--org-xsmall);
    display: flex;
    flex-direction: column;
    gap: var(--org-small);
    color: var(--color-text-primary);
  }

  .top-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: var(--color-text-secondary);
    margin-left: 2px;
  }

  .dragOver {
    padding: 2px;
    padding-top: 3px;
    border: 3px solid #3d81d8;
    background: #0c366c;
  }
</style>
