<script lang="ts">
  import { orgFolder, orgPlus } from "@nrk/origo";
  import UploadButton from "components/Upload/UploadButton.svelte";
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";

  import { getAllImagesLink, getMyImagesLink, pageState } from "state/page";
  import { defaultSearchParams, type SearchParams } from "state/params";
  import { getSearchHitsFromQuery } from "state/queries";
  import { createMimirSearchQuery } from "state/queries/mimir";

  import ImageGroup from "./ImageGroup.svelte";
  import Folder from "components/Folders/Folder.svelte";
  import { setFolderFilter } from "components/Folders/folders";
  import { useFeatureToggle } from "services/configCatService";
  import { getFolder } from "services/folders/api";

  import type { Folder as FolderType } from "services/folders/types";

  $: allImagesLink = getAllImagesLink($pageState);
  $: myImagesLink = getMyImagesLink($pageState);
  const recentImagesQuery = createMimirSearchQuery({ ...defaultSearchParams });
  const recentImages = getSearchHitsFromQuery(recentImagesQuery);
  const myImagesParams: SearchParams = { ...defaultSearchParams, owner: "current-user" };
  const myImagesQuery = createMimirSearchQuery(myImagesParams);
  const myImages = getSearchHitsFromQuery(myImagesQuery);

  let recentFolders: { folder?: FolderType; parent?: FolderType }[] = [];

  let mounted = false;
  onMount(() => {
    mounted = true;
    recentFolders = JSON.parse(localStorage.getItem("recentFolders") || "[]");
  });

  $: relevantFolderIds = recentFolders.map((recent) => recent?.folder?.id);
</script>

<div class="wrapper">
  <div class="group">
    <h2><a href={allImagesLink}>Nyeste</a></h2>
    {#if $recentImagesQuery.isLoading}
      {#if mounted}
        <span transition:fade={{ delay: 1400, duration: 200 }} class="status">Henter… </span>
      {/if}
    {:else if $recentImagesQuery.isError}
      <span class="status">Kunne ikke hente.</span>
    {:else}
      <ImageGroup images={$recentImages.slice(0, 25)}></ImageGroup>
    {/if}
  </div>
  <div class="group">
    <h2><a href={myImagesLink}>Mine bilder</a></h2>
    {#if $myImagesQuery.isLoading}
      {#if mounted}
        <span transition:fade={{ delay: 1400, duration: 200 }} class="status">Henter… </span>
      {/if}
    {:else if $myImagesQuery.isError}
      <span class="status">Kunne ikke hente.</span>
    {:else}
      <ImageGroup images={$myImages.slice(0, 7)} context={myImagesParams}>
        <svelte-fragment slot="ifSpace">
          <div class="placeholder">
            <UploadButton>
              <span>
                {@html orgPlus} Legg til bilde
              </span>
            </UploadButton>
          </div>
        </svelte-fragment>
      </ImageGroup>
    {/if}
  </div>
  {#await useFeatureToggle("showFolders") then featureEnabled}
    {#if featureEnabled && recentFolders.length > 0}
      <div class="group">
        <h2>Produksjoner</h2>
        <div style="display: flex; flex-direction: row; ">
          {#each relevantFolderIds.slice(relevantFolderIds.length - 1, relevantFolderIds.length) as relevantFolderId}
            <div style="width: 200px">
              <div>
                <button
                  class="org-button icon"
                  on:click={() => setFolderFilter(relevantFolderId || "")}
                >
                  <span style="font-size: .65rem">{@html orgFolder}</span>
                  {#await getFolder(relevantFolderId || "") then folder}
                    {folder?.name || "Urix 21. august"}
                  {/await}
                </button>
              </div>
              <div class="folder">
                <a
                  href={`images?folderId=${relevantFolderId}`}
                  on:click={() => setFolderFilter(relevantFolderId || "")}
                >
                  <Folder
                    folder={{
                      id: relevantFolderId || "",
                      name: "",
                      hasSubFolder: true,
                    }}
                    contentOnly={true}
                  />
                </a>
              </div>
            </div>
          {/each}
        </div>
      </div>
    {/if}
  {/await}
</div>

<style>
  .wrapper {
    padding: 0.5em 1.5em;
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 100%;
    flex: 1;
    min-width: 0;
  }

  h2 {
    font-size: 0.95rem;
    font-weight: 600;
  }

  .placeholder {
    display: flex;
    height: calc(100% - 1px);
    width: 130px;
    flex-direction: column;
    align-items: stretch;
    align-content: center;
    justify-content: center;
    color: var(--color-text-inactive);
    border: 1px dashed var(--color-text-inactive);
    border-radius: 4px;
    font-weight: 500;
  }

  .placeholder:hover {
    cursor: pointer;
    background: var(--color-surface-secondary);
    color: var(--color-text-tertiary);
  }

  .status {
    color: var(--color-text-inactive);
  }

  .group {
    min-height: 150px;
  }

  .folder {
    max-width: 200px;
    max-height: 140px;
    overflow: hidden;
    border-radius: var(--org-medium);
  }

  .icon {
    display: flex;
    align-items: center;
    gap: var(--org-xsmall);
  }
</style>
