<script lang="ts">
  import {
    orgChevronClosed,
    orgChevronOpen,
    orgDotdotdot,
    orgExternalLink,
    orgGarbage,
  } from "@nrk/origo";
  import { createMutation, createQuery, useQueryClient } from "@tanstack/svelte-query";
  import {
    addItemToFolder,
    createFolder,
    deleteFolder,
    getFolderContent,
    importKaleidoImage,
    importNtbImage,
    removeItemFromFolder,
    updateFolder,
  } from "services/folders/api";
  import { getDetailLink, expandedFolders, recentFolders } from "state/page";
  // retain module scoped expansion state for each tree node
  import { format } from "date-fns";
  import nbLocale from "date-fns/locale/nb";
  import { isEqual, sortBy, upperFirst, omit, takeRight } from "lodash-es";
  import type { Folder, FolderImage, FolderMetadata } from "services/folders/types";
  import { createEventDispatcher, onDestroy, onMount } from "svelte";
  import { fade } from "svelte/transition";
  import EditFolderButton from "./EditFolderButton.svelte";
  import { isoDateRe, setFolderFilter, sortDailyShows } from "./folders";
  import NewFolderButton from "./NewFolderButton.svelte";
  import { isEmbedded } from "config";
  import { copyNtbImageToMimir } from "services/ntb";
  import { productFruitsInstance } from "services/productFruitsService";

  export let folder: Folder;
  export let parent: Folder | undefined = undefined;
  export let current: { folder?: Folder; parent?: Folder } = {};

  export let contentOnly = false;
  $: expanded = contentOnly || $expandedFolders.has(folder.id);

  let parsedTitleDate =
    (folder?.name?.match(isoDateRe) != undefined && new Date(folder.name)) || undefined;

  let parsedTitle =
    upperFirst(parsedTitleDate && format(parsedTitleDate, "EEEE d. MMMM", { locale: nbLocale })) ||
    folder.name;

  let draggingOver = false;
  let draggingOverHeader = false;
  let draggingImage = false;
  let dragOverRemoveDropZone = false;
  let deleteElement;

  const queryClient = useQueryClient();

  const query = createQuery({
    queryKey: ["folders", folder.id],
    refetchInterval: 10000,
    queryFn: async (context) => {
      const folderId = context.queryKey.at(1);
      if (folderId) return await getFolderContent(folderId);
    },
    onSuccess: (data) => {
      if (data) {
        const childrenChanged = !isEqual(
          sortBy(data.folders.map((f) => f.id)),
          sortBy(folder.children?.map((f) => f.id)),
        );
        const imagesChanged = !isEqual(
          sortBy(data.images.map((i) => i.id)),
          sortBy(folder.images?.map((i) => i.id)),
        );
        if (childrenChanged || imagesChanged) {
          folder.images = data.images;
          folder.children = data.folders.sort((a, b) => a.name?.localeCompare(b.name));
        }
        if (folder.children) {
          // Sort daily shows in order of air time
          folder.children = folder.children.sort(sortDailyShows);
        }
      }
    },
    refetchOnWindowFocus: false,
  });

  const removeItemMutation = createMutation({
    mutationFn: async (mutationData: { folderId: string; imageId: string }) => {
      return await removeItemFromFolder(mutationData.folderId, mutationData.imageId);
    },
    onMutate: async (item) => {
      if (folder.images) folder.images = folder.images.filter((img) => img.id != item.imageId);
    },
    onError: async () => {
      //TODO: test error flow
      queryClient.invalidateQueries(["folders", folder.id]);
      $query.refetch();
    },
  });

  //hypothetical move item between folders solution
  const moveItemMutation = createMutation({
    mutationFn: async (mutationData: {
      fromFolderId: string;
      toFolderId: string;
      image: FolderImage;
    }) => {
      const addItemResult = await addItemToFolder(mutationData.toFolderId, mutationData.image.id);
      const removeItemResult = await removeItemFromFolder(
        mutationData.fromFolderId,
        mutationData.image.id,
      );
      return {
        addition: addItemResult,
        removal: removeItemResult,
      };
    },
    onMutate: (movedItem) => {
      //From folder: folder.images
      folder.images = folder.images?.filter((img) => img.id !== movedItem.image.id);
      //To folder: folder.images
      if (folder.images) folder.images = [...folder.images, movedItem.image];
    },
  });

  const addItemMutation = createMutation({
    mutationFn: async (mutationData: { folderId: string; image: FolderImage }) => {
      if (mutationData.image.type === "Kaleido") {
        const newlyImportedImage = await importKaleidoImage(mutationData.image);
        if (newlyImportedImage)
          return await addItemToFolder(mutationData.folderId, newlyImportedImage.id);
      }
      if (mutationData.image.type === "Ntb") {
        const newlyImportedImage = await importNtbImage(mutationData.image);
        if (newlyImportedImage)
          return await addItemToFolder(mutationData.folderId, newlyImportedImage.id);
      }
      return await addItemToFolder(mutationData.folderId, mutationData.image.id);
    },
    onMutate: (newItem) => {
      if (folder.images) folder.images = [...folder.images, newItem.image];
    },
    onError: async (err, newItem, context) => {
      //TODO: implement error flow
      //console.log(err, newItem, context);
    },
  });

  function persistCurrentFolder(current: { folder?: Folder; parent?: Folder }) {
    const entry = {
      folder: omit(current.folder, ["images"]),
      parent: omit(current.parent, ["children"]),
    };
    console.debug("Push entry", entry);
    $recentFolders.push(entry);
    localStorage.setItem("recentFolders", JSON.stringify(takeRight($recentFolders, 3)));
  }

  const toggleExpansion = async (folderId: string) => {
    if ((folder.children && folder.children.length > 0) || expandLeafFolder) {
      expanded = !expanded;
      expanded ? $expandedFolders.add(folderId) : $expandedFolders.delete(folderId);
    } else {
      persistCurrentFolder({ folder, parent });
      if (isEmbedded()) {
        $productFruitsInstance?.api.events.track("folder-selected-embedded");
        setFolderFilter(folderId);
        expanded = false;
      } else {
        $productFruitsInstance?.api.events.track("folder-selected-standalone");
        console.debug("Dispatch select", folder, parent);
        dispatch("select", { folder, parent, expanded });
      }
    }
  };

  async function _deleteFolder(folderId: string) {
    const response = await deleteFolder(folderId).catch((error) =>
      console.error(`Failed to delete folder '${folderId}'`, error, response),
    );
    nodeRef.parentNode?.removeChild(nodeRef);
  }

  async function _createFolder(name: string, parentId: string) {
    const newFolder = await createFolder(name, parentId);
    if (folder.children) {
      folder.children = [
        ...folder.children,
        {
          name: newFolder.name,
          id: newFolder.id,
          children: [],
          images: [],
          expanded: false,
          hasSubFolder: false,
          locked: newFolder.locked,
        } as Folder,
      ];
    }
  }
  async function _updateMetadataToFolder(folderId: string, metadata: Partial<FolderMetadata>) {
    const updatedFolder = await updateFolder(folderId, metadata);
    folder.name = updatedFolder.name;
  }

  async function droppedImage(event: DragEvent, targetFolderId: string) {
    $productFruitsInstance?.api.events.track("dropped-image-in-folder");
    draggingOver = false;
    const data = event.dataTransfer?.getData("text/plain");
    const imageData: FolderImage & { folderId?: string } = JSON.parse(data ?? "");
    if (!folder.images) {
      folder.images = [];
    }
    if (imageData.thumbnail && folder.images) {
      if (targetFolderId === imageData.folderId) return;
      if (!folder.images?.find((img) => img.id === imageData.id))
        $addItemMutation.mutate({ folderId: targetFolderId, image: imageData });
      if (imageData.folderId) {
        //TODO: solve image movement between folders
        console.warn("Image move from one folder to another not implemented.");
      }
    }
  }
  let nodeRef: Node;

  const dropImageForRemoval = (event: DragEvent, folderId: string) => {
    $productFruitsInstance?.api.events.track("dropped-image-in-removal-area");
    const data = event.dataTransfer?.getData("text/plain");
    const imageData: FolderImage = JSON.parse(data ?? "");
    if (imageData.thumbnail && folder.images) {
      $removeItemMutation.mutate({ folderId, imageId: imageData.id });
      draggingImage = false;
      dragOverRemoveDropZone = false;
    }
  };

  onDestroy(() => {
    $query.remove();
  });

  let mounted = false;
  onMount(() => (mounted = true));

  export let expandLeafFolder = false;

  let showExpandButton = expandLeafFolder && !isEmbedded();
  let showContextMenu = expandLeafFolder && !isEmbedded();

  const dispatch = createEventDispatcher();
  let dragTimer: ReturnType<typeof setTimeout>;
</script>

<li bind:this={nodeRef}>
  {#if !contentOnly}
    <div class="header">
      <div>
        <button
          class="org-button"
          class:drag-highlight={draggingOverHeader}
          on:dragenter={() => {
            draggingOverHeader = true;
            dragTimer = setTimeout(() => {
              toggleExpansion(folder.id);
            }, 750);
          }}
          on:dragleave={() => {
            draggingOverHeader = false;
            clearTimeout(dragTimer);
          }}
          on:click={() => {
            toggleExpansion(folder.id);
          }}
        >
          <span class="expand-wrapper">
            {#if showExpandButton || (folder.children && folder.children.length > 0) || parsedTitleDate}
              {#if expanded}
                {@html orgChevronOpen}
              {:else}
                {@html orgChevronClosed}
              {/if}
            {/if}
          </span>
          {parsedTitle}
        </button>

        {#if mounted && ($query.isLoading || $addItemMutation.isLoading)}
          <span in:fade={{ duration: 0, delay: 1000 }} class="org-spinner spinner"></span>
        {/if}
      </div>
      {#if showContextMenu}
        <button class="org-button context-menu">{@html orgDotdotdot}</button>
        <bb-dropdown hidden>
          {#if !folder.children?.length}
            <button
              class="org-button"
              on:click={() => {
                $productFruitsInstance?.api.events.track("expand-folder");
                setFolderFilter(folder.id);
              }}
            >
              {@html orgExternalLink} Åpne
            </button>
          {/if}
          {#if !folder.images?.length}
            <NewFolderButton onConfirm={(name) => _createFolder(name, folder.id)} />
          {/if}
          <EditFolderButton
            {folder}
            onConfirm={(folderMetadata) => _updateMetadataToFolder(folder.id, folderMetadata)}
          />
          {#if !(folder.children && folder.children.length > 0)}
            <button
              class="org-button"
              on:click={() => {
                _deleteFolder(folder.id);
              }}
              disabled={folder.locked || (folder.children && folder.children.length > 0)}
            >
              {@html orgGarbage} Slett
            </button>
          {/if}
        </bb-dropdown>
      {/if}
    </div>
  {/if}
  {#if expanded}
    {#if !(folder.children && folder.children?.length > 0)}
      <div class="folder-container">
        <div
          class="image-container"
          class:dragOverDropZone={draggingOver}
          on:dragover={(ev) => {
            draggingOver = true;
            ev.preventDefault();
          }}
          on:dragleave={() => (draggingOver = false)}
          on:drop={(event) => droppedImage(event, folder.id)}
          role="button"
          tabindex="0"
        >
          {#if !(folder.images && folder.images.length > 0) && $query.isFetched}
            <span class="org-muted placeholder-text">Ingen bilder</span>
          {/if}

          {#each folder.images || [] as img (img.id)}
            {#if img.type === "Mimir"}
              <a
                href={getDetailLink(img.id)}
                style="text-decoration: none;"
                draggable="true"
                on:dragstart={(e) => {
                  e.dataTransfer?.setData(
                    "text/plain",
                    JSON.stringify({ ...img, folderId: folder.id }),
                  );
                  draggingImage = true;
                }}
                on:dragend={(e) => {
                  draggingImage = false;
                }}
              >
                <img class="thumb" src={img.thumbnail} alt={img.title} />
              </a>
            {:else}
              <div class="thumbwrapper" draggable="false">
                <div class="org-spinner image-importing-spinner" />
                <img
                  draggable="false"
                  class="thumb thumb-loading"
                  src={img.thumbnail}
                  alt={img.title}
                />
              </div>
            {/if}
          {/each}
        </div>
        {#if draggingImage}
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <div
            bind:this={deleteElement}
            class="remove-image-box"
            in:fade={{ delay: 50, duration: 200 }}
            out:fade={{ delay: 50, duration: 200 }}
            class:dragOverDropZone={dragOverRemoveDropZone}
            on:dragover|preventDefault={() => {
              dragOverRemoveDropZone = true;
            }}
            on:dragleave={(event) => {
              if (event.relatedTarget && deleteElement.contains(event.relatedTarget)) return;
              dragOverRemoveDropZone = false;
            }}
            on:drop={(event) => dropImageForRemoval(event, folder.id)}
          >
            {@html orgGarbage}
          </div>
        {/if}
      </div>
    {/if}

    {#if expanded && folder.children && $query.isSuccess}
      {#each folder.children as child (child.id)}
        <svelte:self folder={child} parent={folder} {expandLeafFolder} bind:current on:select />
      {/each}
    {/if}
  {/if}
</li>

<style>
  .remove-image-box {
    width: 100%;
    height: 50px;
    margin-top: var(--org-small);
    border-radius: var(--org-small);
    padding: var(--org-small);
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--org-color-shade-2);
  }
  li {
    list-style: none;
    margin-left: var(--org-small);
  }
  .thumb {
    max-width: 50px;
    max-height: 40px;
    padding: 4px;
    border-radius: 3px;
  }
  .thumb-loading {
    filter: brightness(50%);
  }
  .image-container {
    border: none;
    padding: var(--org-small);
    background-color: var(--org-color-shade-1);
    width: 100%;
    min-height: 200px;
    margin-right: var(--org-small);
    border-radius: var(--org-small);
    display: flex;
    flex-wrap: wrap;
    align-content: start;
    gap: var(--org-xsmall);
  }
  .thumbwrapper {
    max-width: 50px;
    max-height: 40px;
  }
  .image-importing-spinner {
    font-size: 0.3rem;
    position: relative;
    z-index: 1;
    top: 50%;
    bottom: 50%;
  }
  .org-spinner:after {
    color: white;
  }
  .dragOverDropZone {
    outline: 2px dashed var(--org-color-primary);
    background-color: var(--org-color-shade-2);
  }
  .header {
    display: flex;
    justify-content: space-between;
  }
  .spinner {
    font-size: 0.5rem;
    margin-left: 1rem;
  }
  .placeholder-text {
    padding: 1rem;
  }
  .expand-wrapper {
    display: inline-block;
    min-width: 1.5rem;
    font-size: 0.75rem;
  }

  .header .context-menu {
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s visibility 0.5s;
  }

  .header:hover .context-menu {
    opacity: 1;
    visibility: visible;
  }

  .drag-highlight {
    background-color: var(--org-color-shade-1, hsla(210, 15%, 50%, 0.2));
  }
</style>
