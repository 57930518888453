<svelte:options immutable />

<script lang="ts">
  import { useSelector } from "@xstate/svelte";
  import type { SearchHit } from "services/searchHit";
  import SelectionService, { isItemSelected } from "state/SelectionService";
  import { tableImageSize } from "../imageSize";
  import { infiniteScroll } from "../infiniteScroll";
  import type { SearchHitHandler } from "../searchHitHandler";

  import ImageTableRow from "./ImageTableRow.svelte";
  import { isEmbedded } from "../../../config.js";

  export let searchHits: SearchHit[] = [];
  export let getMore: () => Promise<void>;

  export let handler: SearchHitHandler;

  export let onKeyDown: (event: KeyboardEvent) => void;
  export let createPreloader: (searchHit: SearchHit) => () => Promise<void>;
</script>

<div class="container">
  <table
    class="org-table table"
    style={`--scale: ${Math.sinh(isEmbedded() ? 1 : $tableImageSize) + 0.8};`}
  >
    <thead>
      <tr>
        <th />
        <th>Tittel</th>
        <th class="large">Beskrivelse</th>
        <th class="for-desktop">Fotograf</th>
        <th class="right">Fotografert</th>
        <th class="for-desktop">Vilkår</th>
      </tr>
    </thead>
    <tbody use:infiniteScroll={searchHits && getMore}>
      <!-- Explicitily wrap item in key block to load
           and render elements in ascending order  -->
      {#each searchHits as searchHit}
        {#key searchHit.id}
          <ImageTableRow
            {searchHit}
            selected={useSelector(SelectionService, isItemSelected(searchHit.id))}
            selectable={true}
            preloader={createPreloader(searchHit)}
            on:keydown={onKeyDown}
            {handler}
          />
        {/key}
      {/each}
    </tbody>
  </table>
</div>

<style>
  .container {
    width: 100%;
    max-height: 100%;
    isolation: isolate;
  }

  table {
    max-height: 100%;
  }

  thead {
    top: 0;
    position: sticky;
    background-color: var(--color-surface-secondary);
    z-index: 1;
  }

  th.right {
    text-align: right;
  }

  th.large {
    width: 40%;
  }

  .table {
    /* offset fixed bottom bar, make room for focus-shadow */
    padding: 0 3px 50px 3px;
  }

  /*Hack to reduce empty space*/
  th:first-child {
    padding: 0;
  }

  .for-desktop {
    display: table-cell;
  }

  @media screen and (max-width: 1024px) {
    .for-desktop {
      display: none;
    }

    .container {
      overflow: unset;
      max-height: unset;
    }
  }
</style>
