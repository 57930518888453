<script lang="ts">
  import { nrkCategoryActive, nrkChevronDown, nrkList } from "@nrk/core-icons";
  import { sortParams, updateSortParams } from "state/page";
  import { currentViewType, isViewType } from "./viewType";

  function setSortBy(value: import("state/params").SortParams["sortBy"]) {
    return function (this: HTMLInputElement) {
      if (!this.checked) return;
      updateSortParams({ sortBy: value });
    };
  }

  function setSortOrder(value: import("state/params").SortParams["sortOrder"]) {
    return function (this: HTMLInputElement) {
      if (!this.checked) return;
      updateSortParams({ sortOrder: value });
    };
  }

  const sortableFields: { field: import("state/params").SortParams["sortBy"]; label: string }[] = [
    {
      field: "mediaCreatedOn",
      label: "Fotografert",
    },
    {
      field: "createdOn",
      label: "Lastet opp",
    },
    // We leave this out until it is explicitly asked for by users
    // {
    //   field: "modifiedOn",
    //   label: "Sist endret",
    // },
  ];

  const sortOrders: { order: import("state/params").SortParams["sortOrder"]; label: string }[] = [
    {
      order: "desc",
      label: "Nyeste først",
    },
    {
      order: "asc",
      label: "Eldste først",
    },
  ];

  function handleChange(event: Event) {
    const target = event.target as HTMLInputElement;

    if (isViewType(target.value)) {
      currentViewType.set(target.value);
      viewTypeDropdownEl.hidden = true;
    }
  }

  let viewTypeDropdownEl: HTMLElement;
</script>

<div class="wrapper">
  <button class="org-button dropdown-button">
    {sortableFields.find((field) => field.field === $sortParams.sortBy)?.label}
    <span class="dropdown-hint-icon">{@html nrkChevronDown}</span>
  </button>
  <bb-dropdown class="sort" hidden>
    <fieldset class="org-fieldset">
      <legend>Sorter etter</legend>
      {#each sortableFields as field (field.field)}
        <label>
          <input
            class="org-input"
            type="radio"
            name="sort-by"
            value={field.field}
            checked={$sortParams.sortBy === field.field}
            on:change={setSortBy(field.field)}
          />
          {field.label}
        </label>
      {/each}
    </fieldset>
    <fieldset class="org-fieldset">
      <legend>Rekkefølge</legend>
      {#each sortOrders as order (order.order)}
        <label>
          <input
            class="org-input"
            type="radio"
            value={order.order}
            name="sort-order"
            checked={$sortParams.sortOrder === order.order}
            on:change={setSortOrder(order.order)}
          />
          {order.label}
        </label>
      {/each}
    </fieldset>
  </bb-dropdown>

  <button class="org-button dropdown-button">
    {@html $currentViewType === "TILES" ? nrkCategoryActive : nrkList}
    <span class="dropdown-hint-icon">
      {@html nrkChevronDown}
    </span>
  </button>
  <bb-dropdown hidden bind:this={viewTypeDropdownEl}>
    <label class="org-button">
      <input
        type="radio"
        name="view-type"
        value="TILES"
        checked={$currentViewType === "TILES"}
        on:change={handleChange}
      />
      {@html nrkCategoryActive} Rutenett
    </label>
    <label class="org-button">
      <input
        type="radio"
        name="view-type"
        value="TABLE"
        checked={$currentViewType === "TABLE"}
        on:change={handleChange}
      />
      {@html nrkList} Liste
    </label>
  </bb-dropdown>
</div>

<style>
  .wrapper {
    display: flex;
    flex-direction: row;
  }

  bb-dropdown {
    background: var(--color-surface-secondary);
    color: var(--color-text-primary);
  }

  button {
    font-weight: normal;
  }

  .dropdown-button {
    display: flex;
    align-items: flex-start;
    gap: 5px;
  }

  .dropdown-hint-icon {
    font-size: 0.5rem;
  }

  .sort label {
    display: block;
    margin: var(--org-small);
  }
  bb-dropdown.sort {
    padding: var(--org-small);
  }
</style>
