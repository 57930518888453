<script lang="ts">
  import { eachDayOfInterval, getWeek, startOfWeek } from "date-fns";
  import { dailyProductionsTemplate, generateProductionFolders, type Path } from "./folders";
  import { nb } from "date-fns/locale";
  import { writable, type Writable } from "svelte/store";
  import { createEventDispatcher, onMount } from "svelte";

  export let folderDate: Date | undefined;

  let createFoldersProgress: Writable<number | undefined> = writable(undefined);

  const dispatch = createEventDispatcher();

  let createFolderError: boolean;
  let status: string;
  let retries = 0;

  async function onGenerateFolders() {
    createFolderError = false;
    status = "Oppretter mapper…";

    if (!folderDate) {
      return;
    }
    const firstDayOfWeek = startOfWeek(folderDate, { locale: nb });
    const week = eachDayOfInterval({
      start: firstDayOfWeek,
      end: new Date().setDate(firstDayOfWeek.getDate() + 6),
    });

    unsavedChanges = true;
    let result: Promise<Path[]>;
    ({ progress: createFoldersProgress, result } = generateProductionFolders(
      week,
      dailyProductionsTemplate,
    ));

    dispatch("creatingFolders", result);

    result
      .then((folders) => {
        console.debug(`Created ${folders.length} folders`, folders);
        dispatch("createdFolders");
      })
      .catch((error) => {
        console.warn("Unable to create folders", error);
        createFolderError = true;
        status = "Kunne ikke opprette mapper";
      })
      .finally(() => {
        setTimeout(() => {
          unsavedChanges = false;
        }, 1000);
      });
  }

  const beforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = true;
  };

  export let unsavedChanges = false;

  $: {
    unsavedChanges
      ? window.addEventListener("beforeunload", beforeUnload)
      : window.removeEventListener("beforeunload", beforeUnload);
  }
  onMount(() => {
    return () => {
      window.removeEventListener("beforeunload", beforeUnload);
    };
  });

  const progressBarMax = 0.99;
</script>

<div class="wrapper">
  {#if $createFoldersProgress === undefined}
    <div class="org-muted">
      Ingen mapper for uke {folderDate && getWeek(folderDate)}
    </div>
    <button class="org-button org-button--secondary" on:click={onGenerateFolders}>
      Opprett mapper
    </button>
  {:else}
    <div class="org-muted">{status}</div>
    <div style="width: 70%">
      <bb-progress
        value={$createFoldersProgress < progressBarMax ? $createFoldersProgress : " "}
        error={createFolderError}
      />
    </div>
  {/if}
  {#if createFolderError && retries < 2}
    <button
      class="org-button org-button--secondary"
      on:click={() => {
        retries += 1;
        onGenerateFolders();
      }}>Prøv igjen</button
    >
  {:else if createFolderError}
    <button class="org-button org-button--secondary" on:click={() => {}}>Vis uansett</button>
  {/if}
</div>

<style>
  .wrapper {
    margin: 1rem;
    display: flex;
    flex-direction: column;
    gap: var(--org-medium);
    align-items: center;
  }
</style>
