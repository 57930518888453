import { type ImageBoundriesToKaleido } from "components/Plugin/services/derivate";
import type { SearchParams } from "state/params";
import { PAGE_SIZE } from "state/params";
import { getKaleidoConfig, getKaleidoEnvironment, getKaleidoProxyUrl } from "../../config";
import { apiFetch } from "../fetch-wrapper";
import { kaleidoImageToSearchHit, mapToSearchHits, type KaleidoSearchHit } from "../searchHit";
import { type Kaleido } from "bildebanken-model";
import { extractKaleidoIdFromUrl } from "../api";

const kaleidoConfig = getKaleidoConfig();
const EXCLUDED_USERS =
  "(kartoteketcapture|snuttcapture|bilderobot|newsagencythumbnails|bildebanken-stage|bildebanken_stage|bildebanken|bildebanken-beta)";

type ElasticQuery = {
  from: number;
  size: number;
  sort: ({ created: { order: "asc" | "desc" } } | { added: { order: "asc" | "desc" } })[];
  query?: Query;
};

type Query = {
  bool: {
    must: Must[];
    must_not: { regexp: { "events.user": string } };
  };
};

type Must = {
  default_operator?: "and";
  match_phrase?: Record<string, string>;
  simple_query_string?: Record<string, string>;
  range?: Record<string, { gte?: number | Date; lte?: number | Date }>;
  match?: Record<string, string>;
};

function buildKaleidoEsQuery(params: SearchParams) {
  const { query, sort, from, filter } = params;
  const searchBody: ElasticQuery = {
    from: from || 0,
    size: PAGE_SIZE,
    sort: [
      sort.sortBy === "mediaCreatedOn"
        ? { created: { order: sort.sortOrder } }
        : { added: { order: sort.sortOrder } },
    ],
  };
  const esQuery: Query = {
    bool: {
      must: [],
      must_not: {
        regexp: {
          "events.user": EXCLUDED_USERS,
        },
      },
    },
  };

  if (query) {
    esQuery.bool.must.push({ simple_query_string: { query: query, default_operator: "and" } });
  }

  if (filter.photographer?.name) {
    esQuery.bool.must.push({
      match_phrase: { creators: filter.photographer.name },
    });
  }

  if (filter.date?.field === "mediaCreatedOn" && filter.date?.type === "Since") {
    esQuery.bool.must.push({
      range: { added: { gte: new Date().getTime() - filter.date.secondsBack * 1000 } },
    });
  } else if (filter.date?.field === "createdOn" && filter.date?.type === "Since") {
    esQuery.bool.must.push({
      range: { created: { gte: new Date().getTime() - filter.date.secondsBack * 1000 } },
    });
  } else if (filter.date?.field === "mediaCreatedOn" && filter.date?.type === "Range") {
    esQuery.bool.must.push({
      range: { created: { gte: filter.date.from?.getTime(), lte: filter.date.to?.getTime() } },
    });
  } else if (filter.date?.field === "createdOn" && filter.date?.type === "Range") {
    esQuery.bool.must.push({
      range: { added: { gte: filter.date.from?.getTime(), lte: filter.date.to?.getTime() } },
    });
  }

  if (filter.rightsMarker === "Free") {
    esQuery.bool.must.push({
      match: { rights: "NRK" },
    });
  }

  searchBody.query = esQuery;

  return searchBody;
}

export type MappedSuccessfulKaleidoSearchResult = {
  total: number;
  hits: KaleidoSearchHit[];
  from: number;
};

/**
 * Search for Kaleido images
 *
 * @param {SearchParams} params Search params
 * @param {AbortSignal} [signal] To abort the request
 * @returns `void` if the request is cancelled via the optional signal
 */
export async function searchKaleido(
  params: SearchParams,
  signal?: AbortSignal,
): Promise<MappedSuccessfulKaleidoSearchResult> {
  if (params.owner || params.filter.sourceSystems?.length || !kaleidoConfig) {
    return { total: 0, hits: [], from: 0 };
  }

  const kaleidoId = extractKaleidoIdFromUrl(params.query);
  if (kaleidoId) {
    params = { ...params, query: kaleidoId };
  }
  const kaleidoElasticQuery = buildKaleidoEsQuery(params);

  const url = new URL("search?plain=true", getKaleidoProxyUrl());
  const response = await apiFetch(url.href, {
    method: "POST",
    body: JSON.stringify(kaleidoElasticQuery),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    signal,
  });

  if (!response.ok) {
    throw Error("Kunne ikke søke i Kaleido");
  }

  const searchResponse = (await response.json()) as Kaleido.SuccessfulSearchResult;

  return {
    total: searchResponse.hits.total,
    hits: mapToSearchHits(
      searchResponse.hits.hits.map((hit) => hit._source),
      kaleidoImageToSearchHit,
    ),
    from: params.from,
  };
}

export async function ingestKaleidoImageToBildebanken(kaleidoId: string) {
  const url = new URL("ingest/" + kaleidoId, getKaleidoProxyUrl());
  const response = await apiFetch(url.href, {
    method: "POST",
  });
  if (!response.ok) {
    throw Error("Kunne ikke importere Kaleido-bilde til Bildebanken");
  }
  const { id } = await response.json();
  return id;
}
export async function getKaleidoDerivateUrl(
  id: string,
  imageBoundries: ImageBoundriesToKaleido,
): Promise<string> {
  const proxyUrl = new URL("preview/" + id, getKaleidoProxyUrl());
  if ("height" in imageBoundries) {
    proxyUrl.searchParams.append("height", imageBoundries.height.toString());
  } else if ("width" in imageBoundries) {
    proxyUrl.searchParams.append("width", imageBoundries.width.toString());
  }
  const response = await apiFetch(proxyUrl.href, {
    method: "GET",
  });

  if (!response.ok) {
    throw Error("Kunne ikke generere forhåndsvisning av Kaleido-bilde");
  }
  const { url } = await response.json();
  return url;
}

export const constructKaleidoEditImageLink = (itemId: string) => {
  const env = getKaleidoEnvironment();
  const url = new URL("https://static.nrk.no/kaleido-ui/latest/skop/index.html");
  url.hash = `#${itemId}`;

  if (env && env !== "production") {
    url.searchParams.append("environment", env);
    return url.toString();
  }

  return url.toString();
};

export const isKaleidoImage = (imageId: string) => imageId.length === 22;

export const getKaleidoMetadata = async (
  id: string,
): Promise<{ width?: number; height?: number; format?: string }> => {
  const proxyUrl = new URL("metadata/" + id, getKaleidoProxyUrl());

  const response = await apiFetch(proxyUrl.href, {
    method: "GET",
  });

  if (!response.ok) {
    throw Error(`Kunne ikke hente metadata for Kaleido-bilde med id: '${id}'`);
  }
  const metadata = await response.json();
  return metadata;
};
