<script lang="ts">
  import { upperFirst } from "lodash-es";
  import { getEnvironmentFromHostname } from "utils/fns";
  import { getBildebankenHost } from "../config";

  /** We default to a no-op click handler so that we get normal link when this is used for loading states, etc */
  export let onClickTitle: (event: MouseEvent) => void = () => {};

  const onClickHeader = () => {
    alert(
      "Takk for at du prøver ut Bildebanken! ♥ \n\nKom gjerne med tilbakemeldinger og innspill på #️⃣digitalspedisjon på Slack. 🐧",
    );
  };

  const environment = getEnvironmentFromHostname(getBildebankenHost().hostname);

  const environmentColors = {
    dev: "--nrk-color-warm-mint-600",
    stage: "--nrk-color-warm-orange-600",
    beta: "--nrk-color-core-blue-600",
  };
</script>

<header>
  <h1>
    {#if environment !== "prod"}
      <div class="banner" style={`background: var(${environmentColors[environment]})`}>
        <button class="reset" on:click|preventDefault={onClickHeader}>
          {upperFirst(environment)}
        </button>
      </div>
    {/if}
    <a href={getBildebankenHost().href} on:click={onClickTitle} class="title">Bildebanken</a>
  </h1>
  <slot />
</header>

<style>
  header {
    background: var(--org-color-black);
    color: var(--org-color-white);
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 var(--org-medium);
    margin: 0;
  }

  .title,
  .title:hover {
    text-decoration: none;
  }

  .banner {
    position: absolute;
    font-size: 0.8rem;
    rotate: -50deg;
    width: 80px;
    height: 19px;
    text-align: center;
    top: 6px;
    left: -25px;
    transition: 0.35s;
  }

  .banner:hover {
    scale: 1.5;
    rotate: -40deg;
    transition: 1.2s;
  }

  .banner button {
    cursor: help;
    color: white;
    font-weight: normal;
  }

  .reset {
    font-family: inherit; /* 1 */
    font-size: 100%; /* 1 */
    line-height: 1.15; /* 1 */
    margin: 0;
    background: none;
    border: none;
  }
</style>
