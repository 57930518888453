import type { SearchHit } from "services/searchHit";
import type { FolderImage } from "./types";

export function mapSearchHitToFolderImage(searchHit: SearchHit): FolderImage {
  return {
    id: searchHit.id,
    thumbnail: searchHit.thumbnailUrl || undefined,
    title: searchHit.metadata.title || "Ingen tittel",
    type: searchHit.type,
  };
}
