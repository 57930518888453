import type { QueryClient } from "@tanstack/svelte-query";
import { isEqual, uniqBy } from "lodash-es";
import type { Readable } from "svelte/store";
import { get } from "svelte/store";
import { assign, createMachine, type ActorRefFrom, type StateFrom } from "xstate";

import type { EditableMetadata, Image, PersonInImage } from "bildebanken-model";
import { ROLES, calculateRightsMarker, isPersonInImage } from "bildebanken-model";
import { getMetadataItem, updateMetadata, type ImagePatchWithId } from "services/api";
import { getGeneratedAltText } from "services/cognitive";
import { authorityToContact, currentUserAuthority } from "services/authority";
import { upsertMimirImage, upsertMimirSearchResultsWithImage } from "state/queries/mimir";
import { getFailedUploads, isUploadInProgress, type UploadsState } from "state/upload";
import { enrichMetadataFromIptc, getIptc } from "utils/exif-iptc";
import { diffingDerivedStore, mapImageTypeToDigitalSourceType } from "utils/fns";
import { getDefaultVisibility } from "./visibility";
import type { SearchHit } from "services/searchHit";

export type EditableFieldKey = keyof EditableMetadata;

export const fieldLabels: Partial<Record<EditableFieldKey, string>> = {
  title: "Tittel",
  rights: "Bruksvilkår",
  imageType: "Bildetype",
  altText: "Alternativ tekst",
  description: "Beskrivelse",
};

type EditedImage = Image & { altTextSuggestion?: string };
type EditImageContext = {
  imageIds: string[];
  /** keep original state to be able to compare changes */
  originals: Image[];
  /** for display, originals with changes applied */
  images: EditedImage[];
  /** will eventually be sent to server */
  changes: ImagePatchWithId[];
  current: number;
  faceDetectionError?: string;
  altTextSuggestionError?: string;
  requiredFields: EditableFieldKey[];
  failedUploadImageIds: string[];
  uploads?: Readable<UploadsState>;
  client?: QueryClient;
  error?: Error;
  gettingAltTextSuggestion: boolean;
};

type EditMetadataEvent = {
  type: "EDIT_METADATA";
  field: keyof EditableMetadata;
  value: EditableMetadata[keyof EditableMetadata];
};

type EditVisibilityEvent = {
  type: "EDIT_VISIBILITY";
  value: ImagePatchWithId["visibleTo"];
};

export type EditPersonEvent =
  | {
      type: "EDIT_PERSON";
      resId: string;
      field: "detectedFace.hidden" | "detectedFace.verified";
      value: boolean;
    }
  | { type: "EDIT_PERSON"; resId: string; field: "detectedFace.personId"; value: string }
  | {
      type: "EDIT_PERSON";
      resId: string;
      field: "contact";
      value: { resId: string; title: string };
    };

type EditImageEvent =
  | { type: "SHOW_ALL" }
  | { type: "SHOW_SINGLE" }
  | { type: "SET_IMAGES"; imageIds: string[] }
  | { type: "SELECT_IMAGE"; imageId: string }
  | { type: "FACE_DETECTION_ERROR"; message: string }
  | { type: "OPEN_EDIT_PEOPLE_IN_IMAGE" }
  | { type: "CLOSE_EDIT_PEOPLE_IN_IMAGE" }
  | { type: "RESET_FACES_FOR_CURRENT_IMAGE" }
  | EditPersonEvent
  | EditMetadataEvent
  | EditVisibilityEvent
  | { type: "UPLOADS_FAILED"; imageIds: string[] }
  | { type: "SUBMIT" };

const editFieldOnCurrentImage = <K extends EditableFieldKey, V = EditableMetadata[K]>(
  { images, current }: EditImageContext,
  field: K,
  value: V,
) => {
  const copy = images.slice();
  copy[current] = {
    ...images[current],
    metadata: { ...images[current].metadata, [field]: value },
  };
  return copy;
};

const addChangeForCurrentImage = <K extends EditableFieldKey, V = EditableMetadata[K]>(
  { originals, current, changes }: EditImageContext,
  field: K,
  value: V,
) => {
  const copy = changes.slice();
  copy[current].metadataDelta = { ...changes[current].metadataDelta, [field]: value };
  if (copy[current].metadataDelta![field] === originals[current].metadata[field]) {
    delete copy[current].metadataDelta![field];
  }
  return copy;
};

/**** Actions ****/

const assignAltTextSuggestionForCurrentImage = assign<
  EditImageContext,
  EditMetadataEvent & { data: unknown }
>({
  images: (context, event) => editFieldOnCurrentImage(context, "altTextSuggestion", event.data),
  gettingAltTextSuggestion: () => false,
});
const assignFieldForCurrentImage = assign<EditImageContext, EditMetadataEvent>({
  images: (context, event) => editFieldOnCurrentImage(context, event.field, event.value),
  changes: (context, event) => addChangeForCurrentImage(context, event.field, event.value),
});

const assignFieldForAllImages = assign<EditImageContext, EditMetadataEvent>({
  images: ({ images }, event) =>
    images.map((image) => ({
      ...image,
      metadata: { ...image.metadata, [event.field]: event.value },
    })),
  changes: ({ changes, originals }, event) =>
    changes.map((edit, i) => {
      const newValue = {
        ...edit,
        metadataDelta: {
          ...edit.metadataDelta,
          [event.field]: event.value,
        },
      };

      if (event.value === originals[i].metadata[event.field]) {
        delete newValue.metadataDelta[event.field];
      }
      return newValue;
    }),
});

const assignVisibilityForCurrentImage = assign<EditImageContext, EditVisibilityEvent>({
  images: ({ images, current }, event) => {
    const copy = images.slice();
    copy[current] = { ...copy[current], visibleTo: event.value };
    return copy;
  },
  changes: ({ originals, current, changes }, event) => {
    const copy = changes.slice();
    copy[current].visibleTo = event.value;

    if (isEqual(copy[current].visibleTo, originals[current].visibleTo)) {
      delete copy[current].visibleTo;
    }

    return copy;
  },
});

const assignVisibilityForAllImages = assign<EditImageContext, EditVisibilityEvent>({
  images: ({ images }, event) =>
    images.map((image) => ({
      ...image,
      visibleTo: event.value,
    })),
  changes: ({ changes, originals }, event) =>
    changes.map((edit, i) => {
      const newValue = {
        ...edit,
        visibleTo: event.value,
      };

      if (event.value === originals[i].visibleTo) {
        delete newValue.visibleTo;
      }

      return newValue;
    }),
});

/** Selectors **/

export const getCurrentImage = ({ context: { current, images } }: EditImageState) =>
  images[current];
export const getPeopleInCurrentImage = ({
  context: { current, images },
}: EditImageState): PersonInImage[] =>
  images[current]?.metadata.contributors?.filter(isPersonInImage) ?? [];

export const getAllEditedImages = ({ context }: EditImageState): Image[] => context.images;
export const getAllChanges = ({ context }: EditImageState): ImagePatchWithId[] => context.changes;

/**
 * Sends an event to service to update the value of a given field.
 *
 * (I wasn't able to get field/value type inference to work when calling service.send()
 * directly from components. This helper function solves that.)
 */
export function updateField<K extends EditableFieldKey>(
  service: EditImageActor,
  field: K,
  value: EditableMetadata[K],
) {
  service.send({
    type: "EDIT_METADATA",
    field,
    value,
  });
}

export type EditImageState = StateFrom<typeof editImageMachine>;
export type EditImageActor = ActorRefFrom<typeof editImageMachine>;

type GetMetadataServiceResponse = { type: string; data: Image[] };

const assignInitialData = assign<EditImageContext, GetMetadataServiceResponse>({
  images: ({ images, uploads }, { data }) =>
    uniqBy(data.concat(images), "id").map((image) => {
      const result = { ...image };

      // if description contains only whitespaces replace with empty string
      if (image.metadata.description && image.metadata.description.trim().length === 0) {
        image.metadata.description = "";
      }

      if (uploads && isUploadInProgress(get(uploads))) {
        // set uploaded
        if (currentUserAuthority) {
          const contact = authorityToContact(currentUserAuthority);
          result.metadata = {
            ...image.metadata,
            contributors: [{ contact, role: { resId: ROLES.uploader } }],
          };
        }

        // Try to get some data from IPTC
        const data = getIptc(image.id);
        if (data) {
          result.metadata = enrichMetadataFromIptc(data, result.metadata);
        }

        // Set default visibility fo everyone
        result.visibleTo = getDefaultVisibility();
      }

      return result;
    }),
  originals: ({ originals }, { data }) => uniqBy(data.concat(originals), "id"),
});

const initializeChanges = assign<EditImageContext, EditImageEvent>({
  changes: ({ images, uploads }) => {
    const uploading = uploads && isUploadInProgress(get(uploads));
    return images.map((image) => {
      const patch: ImagePatchWithId = { id: image.id, metadataDelta: {} };

      if (uploading) {
        // Make sure upload default data are recorded as changes
        patch.metadataDelta = image.metadata;
        patch.visibleTo = image.visibleTo;
      }

      return patch;
    });
  },
});

/** Machine **/

export const editImageMachine = createMachine<EditImageContext, EditImageEvent>({
  id: "edit",
  initial: "Loading",
  predictableActionArguments: true,
  on: {
    UPLOADS_FAILED: {
      actions: assign({
        failedUploadImageIds: (_, event) => event.imageIds,
      }),
    },
  },
  // In case editing is in context of and upload, connect to uploads store
  // and keep track of failed uploads to avoid trying to update their metadata when saving.
  invoke: {
    src: (context) => (send) => {
      if (!context.uploads) return;
      const failedUploadIds = diffingDerivedStore(context.uploads, (state) =>
        getFailedUploads(state)
          .map((upload) => upload.id)
          .filter(Boolean),
      ) as Readable<string[]>;

      return failedUploadIds.subscribe((imageIds) => {
        send({ type: "UPLOADS_FAILED", imageIds });
      });
    },
  },
  states: {
    Loading: {
      invoke: {
        src: (context) => Promise.all(context.imageIds.map(getMetadataItem)),
        onDone: [
          {
            actions: assignInitialData,
            target: "Editing",
          },
        ],
        onError: {
          target: "Error",
          actions: [
            assign({
              error: (context, event) =>
                event.data && event.data instanceof Error ? event.data : new Error("Ukjent feil"),
            }),
          ],
        },
      },
    },
    Editing: {
      initial: "Determining",
      on: {
        SUBMIT: "Saving",
      },
      entry: [initializeChanges],
      states: {
        Determining: {
          always: [
            { cond: ({ images }) => images.length === 1, target: "Single" },
            { target: "All" },
          ],
        },
        All: {
          on: {
            SHOW_SINGLE: "Single",
            EDIT_METADATA: {
              actions: [assignFieldForAllImages],
            },
            EDIT_VISIBILITY: {
              actions: [assignVisibilityForAllImages],
            },
          },
        },
        Single: {
          entry: [
            assign({ faceDetectionError: () => undefined }),
            assign({ gettingAltTextSuggestion: true }),
          ],
          invoke: {
            src: async (context) => {
              const altTextSuggest = context.images[context.current].metadata.altTextSuggestion;
              if (!altTextSuggest && !context.images[context.current].metadata.altText) {
                const altText = await getGeneratedAltText(context.images[context.current].id);
                return Promise.resolve(altText.caption?.nb);
              } else {
                return Promise.resolve(altTextSuggest);
              }
            },
            onDone: {
              actions: [assignAltTextSuggestionForCurrentImage],
            },
            onError: {
              actions: [
                assign({
                  altTextSuggestionError: (_, event) => event.data,
                  gettingAltTextSuggestion: () => false,
                }),
              ],
            },
          },
          on: {
            SHOW_ALL: "All",
            SELECT_IMAGE: {
              actions: [
                assign({
                  current: ({ images }, { imageId }) =>
                    images.findIndex(({ id }) => id === imageId),
                }),
              ],
              target: "Single",
              internal: false,
            },
            EDIT_METADATA: {
              actions: [assignFieldForCurrentImage],
            },
            EDIT_VISIBILITY: {
              actions: [assignVisibilityForCurrentImage],
            },
          },
        },
      },
    },
    Saving: {
      invoke: {
        src: (context) =>
          Promise.all(
            context.changes
              .filter(
                (patch) =>
                  (patch.metadataDelta && Object.keys(patch.metadataDelta).length > 0) ||
                  patch.visibleTo,
              )
              .filter((patch) => !context.failedUploadImageIds.includes(patch.id))
              .map((patch) => {
                if (patch.metadataDelta?.imageType && !patch.metadataDelta?.digitalSourceType) {
                  patch.metadataDelta.digitalSourceType = mapImageTypeToDigitalSourceType(
                    patch.metadataDelta?.imageType,
                  );
                }
                return updateMetadata(patch).then((image) => {
                  upsertMimirImage(context.client, image);
                  upsertMimirSearchResultsWithImage(context.client, image);
                });
              }),
          ),
        onDone: "Done",
        onError: {
          target: "Editing",
          actions: [
            assign({
              error: (context, event) =>
                event.data && event.data instanceof Error ? event.data : new Error("Ukjent feil"),
            }),
          ],
        },
      },
    },
    Done: {},
    Error: {},
  },
});

export function fieldMissingInImage(image: Image | SearchHit, field: EditableFieldKey) {
  const contentInField = image.metadata[field];
  if (!contentInField) return true;
  if ((field === "title" || field === "altText") && contentInField.trim().length === 0) {
    return true;
  }
  if (field === "rights" && "rights" in image.metadata)
    return calculateRightsMarker(image.metadata.rights) === "Unknown";
  return false;
}

export function requiredFieldsMissing(state: EditImageState): boolean {
  for (const field of state.context.requiredFields) {
    if (state.context.images.some((image) => fieldMissingInImage(image, field))) return true;
  }
  return false;
}

export function requiredFieldsMissingInImage(
  state: EditImageState,
  itemId: string,
): EditableFieldKey[] {
  const image = state.context.images.find((image) => image.id === itemId);

  if (!image) return [];

  return state.context.requiredFields.filter((field) => fieldMissingInImage(image, field));
}

export function isRequiredField(state: EditImageState, field: EditableFieldKey) {
  return state.context.requiredFields.includes(field);
}

export function fieldMissingForCurrentImage(state: EditImageState, field: EditableFieldKey) {
  return fieldMissingInImage(state.context.images[state.context.current], field);
}
