<script lang="ts">
  import { nrkChevronLeft } from "@nrk/core-icons";
  import {
    orgArrowClose,
    orgArrowOpen,
    orgArrowUp,
    orgDate,
    orgEdit,
    orgLocked,
    orgUser,
  } from "@nrk/origo";
  import type { Image } from "bildebanken-model";
  import { getRightsMarker, isUploader } from "bildebanken-model";
  import CopyWrapper from "components/Common/CopyWrapper.svelte";
  import ImagePreview from "components/Common/ImagePreview.svelte";
  import KaleidoImagePreview from "components/Common/KaleidoImagePreview.svelte";
  import NtbImagePreview from "components/Common/NtbImagePreview.svelte";
  import RightsIndicator from "components/Common/RightsIndicator.svelte";
  import ImageGroup from "components/SearchPage/ImageGroup.svelte";
  import { getBildebankenHost } from "config";
  import { first, isEmpty, mergeWith } from "lodash-es";
  import { useFeatureToggle } from "services/configCatService";
  import { ingestKaleidoImageToBildebanken } from "services/kaleido";
  import {
    isKaleidoSearchHit,
    isMimirSearchHit,
    isNtbSearchHit,
    isSearchHit,
    type SearchHit,
  } from "services/searchHit";
  import {
    preloadNeighbors,
    returnToSearchPage,
    showDetailsForNextItem,
    showDetailsForPreviousItem,
    showEdit,
    type navigationDirection,
  } from "state/page";
  import { getKaleidoSearchHits } from "state/queries/kaleido";
  import {
    createMetadataQuery,
    getMimirSearchHitIds,
    getMimirSearchHits,
  } from "state/queries/mimir";
  import { onDestroy, onMount } from "svelte";

  import { fade } from "svelte/transition";
  import { getErrorMessage, hasImageCreatedDate } from "utils/fns";
  import { getRequiredFields } from "../../services/plugin-customization";
  import {
    publishAndSelect,
    publishAndSelectKaleido,
    publishAndSelectNtb,
  } from "./publishAndSelect";
  import { writable } from "svelte/store";
  import { getNtbSearchHits } from "state/queries/ntb";
  import { fieldMissingInImage, type EditableFieldKey } from "../Editor/editImageMachine";
  import { glassPaneState } from "./GlassPane";

  export let itemId: string;
  let dialogEl: HTMLElement;
  let descriptionEl: HTMLElement;

  const searchHits = getMimirSearchHits();
  const searchHitIds = getMimirSearchHitIds();
  //Only reason this has to be reactive is because of the image carusell and keyboard movement.
  $: searchHitMimir = $searchHits.find((item) => item.id === itemId);

  // Kaleido support
  const searchHitsKaleido = getKaleidoSearchHits();
  const searchHitKaleido = $searchHitsKaleido.find((hit) => hit.id === itemId);

  // Ntb support
  const searchHitsNtb = getNtbSearchHits();
  const searchHitNtb = $searchHitsNtb.find((hit) => hit.id === itemId);
  $: searchHit = searchHitMimir || searchHitKaleido || searchHitNtb;
  $: noSearchContext = searchHit === undefined;

  //Metadata currently only works for Mimir images
  $: query =
    isMimirSearchHit(searchHit) || noSearchContext
      ? createMetadataQuery(itemId, { enabled: true })
      : writable(undefined);
  if ($query && isMimirSearchHit(searchHit)) $query.refetch();
  //
  $: image =
    (isMimirSearchHit(searchHit) || noSearchContext) && $query && $query.isSuccess
      ? $query.data
      : undefined;

  const discardUndefined = (objVale, srcValue) => {
    srcValue === undefined ? objVale : undefined;
  };

  $: metadata =
    (image || searchHit) && mergeWith(image?.metadata, searchHit?.metadata, discardUndefined);

  // Preload Image while loading metadata
  const preload = new Image();
  preload.src = getPreviewUrl(image || searchHit);

  // Preload proxy for neighboring search hits, for faster loading when navigating with arrows
  let direction: navigationDirection = "forwards";
  $: $searchHits.length > 0 && preloadNeighbors(itemId, $searchHits, { direction, amount: 5 });

  function handleDialogEvent(event: Event) {
    let target: HTMLElement = event.target as HTMLElement;
    if (dialogEl === target) {
    }
  }

  $: dialogEl?.addEventListener("dialog.toggle", handleDialogEvent);
  $: imageSelected = false;

  let mounted = false;
  onMount(() => {
    mounted = true;
    document.addEventListener("keydown", arrowNavigate);
    $query?.refetch();
  });

  onDestroy(() => {
    document.removeEventListener("keydown", arrowNavigate);
    dialogEl?.removeEventListener("dialog.toggle", handleDialogEvent);
  });

  function getPreviewUrl(image?: SearchHit | Image) {
    // high-res is too large and can't be cached because the URL is different each time (because of signing)
    // proxy is also way too large (because it's PNG), but smaller than highRes on average
    return (
      (isSearchHit(image) ? image.previewUrl : image?.proxy) ??
      getBildebankenHost() + "/image-icon.svg"
    );
  }

  function arrowNavigate(event: KeyboardEvent & { target: Element }) {
    if (event.metaKey || event.altKey || event.ctrlKey) return;
    if (event.code !== "ArrowRight" && event.code !== "ArrowLeft") return;
    if (event.target.nodeName === "INPUT" || event.target.nodeName === "TEXTAREA") return;

    if (event.code === "ArrowRight") {
      direction = "forwards";
      showDetailsForNextItem($searchHitIds);
    } else {
      direction = "backwards";
      showDetailsForPreviousItem($searchHitIds);
    }
  }

  $: hitOrImage = searchHit || image;

  $: rights = searchHit?.metadata.rightsMarker || (image && getRightsMarker(image)) || undefined;
  $: rightsDescription =
    rights === "Free"
      ? "Fri gjenbruk i NRK"
      : rights === "Restricted"
      ? "Vilkår for gjenbruk"
      : metadata?.rights?.rightsHolder?.contact?.title ||
        first(metadata?.copyrightOwners) ||
        metadata?.rights?.credit;

  // Handle raw dates for Kaleido picutres
  $: mediaCreatedOnRaw = metadata?.mediaCreatedOn || searchHit?.mediaCreatedOn;
  $: mediaCreatedOn = (mediaCreatedOnRaw && new Date(mediaCreatedOnRaw)) || undefined;
  $: itemCreatedOnRaw = metadata?.createdOn || searchHit?.itemCreatedOn;
  $: itemCreatedOn = (itemCreatedOnRaw && new Date(itemCreatedOnRaw)) || undefined;
  let contentHeight = 0;
  let compactDescription = true;

  const toggleCompactDescription = () => {
    compactDescription = !compactDescription;
  };

  //Wrapperfunction to swallow event
  const editKaleidoImageClick = (e) => {
    editKaleidoImage();
  };

  const editKaleidoImage = async (
    options: { selectImageAfterEdit: boolean } = { selectImageAfterEdit: false },
  ) => {
    if (isKaleidoSearchHit(searchHit)) {
      try {
        glassPaneState.set({ state: "VISIBLE", message: "Kopierer bilde til Bildebanken..." });
        const itemId = await ingestKaleidoImageToBildebanken(searchHit.id);
        glassPaneState.set({ state: "HIDDEN" });

        if (itemId) {
          showEdit([itemId], options);
        }
      } catch (error) {
        glassPaneState.set({
          state: "SHOW_ERROR",
          message: `Bildet kunne ikke hentes fra Kaleido. (${error})`,
        });
      }
    }
  };

  const selectImage = async () => {
    if (!fulfillRequirements()) {
      if (isKaleidoSearchHit(searchHit)) {
        await editKaleidoImage({ selectImageAfterEdit: true });
      } else {
        await showEdit([itemId], { selectImageAfterEdit: true });
      }
    } else if (hitOrImage && !searchHitKaleido && !searchHitNtb) {
      imageSelected = true;
      await publishAndSelect(hitOrImage);
      imageSelected = false;
    } else if (searchHitKaleido) {
      await publishAndSelectKaleido(searchHitKaleido);
    } else if (searchHitNtb) {
      await publishAndSelectNtb(searchHitNtb);
    } else {
      console.warn("No image to select..", hitOrImage);
    }
  };
  const editImage = () => showEdit([itemId]);

  // Temporary work-around to display correct image capture time. See issue DS-3234.
  // Only compensate images with image creation date.
  $: imageCreatedDateAvailable = !!hitOrImage && hasImageCreatedDate(hitOrImage);
  $: timeZone = imageCreatedDateAvailable ? "UTC" : undefined;

  function fulfillRequirements(): boolean {
    let requirementsFulfilled = true;
    const imageOrSearchHit = image || searchHit;
    getRequiredFields()?.forEach((requiredField) => {
      if (
        imageOrSearchHit &&
        fieldMissingInImage(imageOrSearchHit, requiredField as EditableFieldKey)
      )
        requirementsFulfilled = false;
    });
    return requirementsFulfilled;
  }

  $: uploader = first(
    metadata?.contributors?.filter(isUploader).map((contributor) => contributor.contact.title),
  );
</script>

<div class="wrapper" data-testid="detail-page-wrapper">
  <div class="top-bar">
    <button
      class="org-button"
      on:click={() => {
        returnToSearchPage();
      }}
    >
      {@html nrkChevronLeft}
    </button>
    <div class="thumbnails-wrapper">
      <ImageGroup images={$searchHits} highlightId={image?.id || searchHit?.id} />
    </div>
  </div>
  {#if $query?.isError && !searchHitKaleido}
    <div class="status">
      <h3>Kunne ikke hente bilde</h3>
      <p>
        Prøv igjen og <a
          href="https://nrk.slack.com/archives/CQAH1H525"
          target="_blank"
          title="#digitalspedisjon på Slack">ta kontakt</a
        > hvis problemet består.
      </p>
      <div class="org-warning">{getErrorMessage($query?.error)}</div>
      <p>
        Bilde-ID:
        <CopyWrapper title="Kopier ID">
          <code style="font-size: .75rem;">{itemId}</code>
        </CopyWrapper>
      </p>
    </div>
  {:else}
    <div class="columns">
      <div class="preview">
        {#key itemId}
          {#if isMimirSearchHit(searchHit) || image}
            <ImagePreview mode="detail" image={image || searchHit} />
          {:else if isKaleidoSearchHit(searchHit)}
            <KaleidoImagePreview image={searchHit} mode="preview" />
          {:else if isNtbSearchHit(searchHit)}
            <NtbImagePreview image={searchHit} mode="preview" />
          {:else if $query?.isLoading}
            {#if mounted}
              <div in:fade={{ delay: 1200, duration: 500 }}>Henter bilde…</div>
            {/if}
          {/if}
        {/key}
      </div>
      <div class="details">
        {#key itemId}
          {#if !isEmpty(metadata)}
            <dl>
              <dt class="org-sr">Tittel</dt>
              <dd class="fill" title="Tittel">
                <strong>{metadata?.title || ""}</strong>
              </dd>
              {#if !isEmpty(metadata?.description?.trim())}
                <dt class="org-sr">Beskrivelse</dt>
                <dd class="fill" title="Beskrivelse">
                  <div>
                    <div
                      bind:clientHeight={contentHeight}
                      bind:this={descriptionEl}
                      id="content"
                      class={compactDescription ? "compact" : ""}
                    >
                      {metadata?.description || "(Ingen beskrivelse)"}
                    </div>
                    {#if descriptionEl?.scrollHeight > 4 * 21}
                      <span>
                        {#if compactDescription}
                          <button class="compactToggleButton" on:click={toggleCompactDescription}>
                            <span>Vis mer</span>
                            {@html orgArrowOpen}
                          </button>
                        {:else}
                          <button class="compactToggleButton" on:click={toggleCompactDescription}>
                            <span>Vis mindre</span>
                            {@html orgArrowClose}
                          </button>
                        {/if}
                      </span>{/if}
                  </div>
                </dd>
              {/if}
              <dt>
                <span style="font-size: .8rem; margin-left: -2px" aria-hidden title="Rettigheter">
                  {#if rights}<RightsIndicator rightsMarker={rights} />{:else}<span
                      class="loading-text">...</span
                    >{/if}
                </span>
                <span class="org-sr">Rettigheter</span>
              </dt>
              <dd>
                {#if rightsDescription}
                  {#if !rightsDescription}
                    Ukjent
                  {:else}
                    {@const credit = rightsDescription || metadata.creditLine}
                    {@const usageTerms = metadata?.usageTerms}
                    {#if credit}
                      {credit}
                    {/if}
                    {#if credit && usageTerms}
                      •
                    {/if}
                    {#if usageTerms}
                      <span class="rights-description">
                        {usageTerms}
                      </span>
                    {/if}
                  {/if}
                {:else if isMimirSearchHit(searchHit) && $query?.isLoading}
                  <span class="loading-text">Henter… </span>
                {:else}
                  Ukjent
                {/if}
              </dd>
              <dt>
                <span class="icon" aria-hidden title="Fotograf">{@html orgUser}</span>
                <span class="org-sr">Fotograf</span>
              </dt>
              <dd>
                {#if metadata?.creators?.join(", ")}
                  {metadata?.creators?.join(", ")}
                {:else if isMimirSearchHit(searchHit) && $query?.isLoading}
                  <span class="loading-text">Henter… </span>
                {:else}
                  <span title="Ukjent fotograf">–</span>
                {/if}
              </dd>
              <dt>
                <span
                  class="icon"
                  aria-hidden
                  title={imageCreatedDateAvailable ? "Fotografert" : "Lastet opp"}
                >
                  {@html orgDate}
                </span>
                <span class="org-sr">Fotografert</span>
              </dt>
              <dd>
                {mediaCreatedOn?.toLocaleDateString("no-NB", {
                  dateStyle: "medium",
                  timeZone,
                }) ?? ""}
                {mediaCreatedOn?.toLocaleTimeString("no-NB", {
                  timeStyle: "short",
                  timeZone,
                }) ?? ""}
              </dd>
              {#if image?.visibleTo?.level === "owner"}
                <dt>
                  <span class="icon" aria-hidden title="Synlighet">
                    {@html orgLocked}
                  </span>
                  <span class="org-sr">Synlighet</span>
                </dt>
                <dd>Kun eier</dd>
              {/if}
            </dl>
            {#await useFeatureToggle("showMoreInCompactDetailsPlugin") then useFeature}
              {#if useFeature}
                {@const uploader = first(
                  metadata.contributors
                    ?.filter(isUploader)
                    .map((contributor) => contributor.contact.title),
                )}
                <details>
                  <summary>Detaljer</summary>
                  <dl>
                    <dt>
                      <span class="icon" aria-hidden title={"Lastet opp"}>
                        {@html orgArrowUp}
                      </span>
                      <span class="org-sr">Lastet opp</span>
                    </dt>
                    <dd>
                      <span title={uploader}>
                        {first(uploader?.split(" ")) || "Importert"}
                      </span>
                      <span title={itemCreatedOn?.toLocaleString("no-NB", { timeZone })}>
                        {itemCreatedOn?.toLocaleDateString("no-NB", {
                          dateStyle: "short",
                          timeZone,
                        }) ?? ""}
                      </span>
                      ({metadata.sourceSystem || metadata.sourceSystem || "Ukjent kilde"})
                    </dd>
                  </dl>
                </details>
              {/if}
            {/await}
            <div class="actions">
              <button
                class="org-button org-button--primary"
                on:click={selectImage}
                aria-busy={imageSelected === true ? "true" : "false"}
              >
                Bruk bilde
              </button>
              {#if !isNtbSearchHit(searchHit)}
                {#if isMimirSearchHit(searchHit) || image}
                  <button on:click={editImage} class="org-button org-button--secondary">
                    Rediger
                  </button>
                {:else}
                  <button class="org-button org-button--secondary" on:click={editKaleidoImageClick}>
                    {@html orgEdit} Rediger
                  </button>
                {/if}
              {/if}
            </div>
          {/if}
        {/key}
      </div>
    </div>
  {/if}
</div>

<style>
  .wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: var(--org-small);
    padding-top: var(--org-small);
    background: var(--color-surface-primary);
    overflow: hidden;
  }

  .compact {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 4;
  }

  .compactToggleButton {
    background-color: var(--color-surface-secondary);
    border: none;
    margin-top: 0.25rem;
    padding-left: 0;
  }
  .compactToggleButton:hover {
    cursor: pointer;
  }
  .compactToggleButton > span {
    text-decoration: underline;
  }

  dl {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: var(--org-small);
    row-gap: var(--org-medium);
  }

  dt,
  dd {
    border: none;
    padding: 0;
    margin: 0;
    overflow: unset;
  }

  dd.fill {
    grid-column: span 2;
  }

  .icon :global(svg) {
    font-size: 0.6rem;
    margin-top: -2px;
  }

  .top-bar {
    display: flex;
    align-items: center;
    gap: 20px;
    align-self: stretch;
    min-height: 45px;
    height: 50px;
  }

  .rights-description {
    background: var(--color-surface-warning);
    border-radius: 4px;
    padding-block: 4px;
    padding-inline: var(--org-xsmall);
  }

  .thumbnails-wrapper {
    overflow-x: scroll;
    height: 100%;
  }

  .columns {
    display: flex;
    flex: 1;
    max-height: calc(100% - 45px);
  }

  .columns .preview {
    flex: 1.5;
    display: flex;
  }

  .columns .details {
    overflow-y: auto;
    flex: 0.5;
    padding: 2rem;
    background: var(--color-surface-secondary);
  }

  .status {
    padding: var(--org-medium);
  }

  a:not(.org-button) {
    text-decoration: underline;
  }

  .actions {
    margin-top: 2em;
  }

  .loading-text {
    color: var(--color-text-inactive);
  }
</style>
