<script lang="ts">
  import { orgLightning, orgMapMarker } from "@nrk/origo";
  import type { Image } from "bildebanken-model";
  import { upperFirst } from "lodash-es";
  export let image: Image;

  $: equipment = formatEquipment(image);
  $: technical = image?.technicalMetadata?.formData;
  $: exposure = {
    iso: technical?.image_exif_ISO,
    focalLength: technical?.image_exif_FocalLengthIn35mmFormat,
    exposureValue: technical?.image_exif_ExposureCompensation,
    aperture: technical?.image_exif_FNumber,
    shutterSpeed: technical?.image_exif_ShutterSpeed,
    flashFired:
      technical?.image_exif_Flash && (technical?.image_exif_Flash).toLowerCase().includes("fired"),
  };

  const prettyModel = { "ILCE-1": "α1", "ILCE-7M3": "α7 III", "DSC-RX100M7": "RX100 VII" };

  type GeoLocation = {
    latitude: number;
    longitude: number;
    altitude?: number;
    accuracy?: number;
    speed?: number;
    direction?: number;
  };

  function parseOptionalFloat(value?: string): number | undefined {
    if (value) {
      const parsed = Number.parseFloat(value);
      return parsed === Number.NaN ? undefined : parsed;
    }
  }

  function isGeolocation(value: Partial<GeoLocation>): value is GeoLocation {
    return Boolean(value.latitude && value.longitude);
  }

  function getLocation(image: Image): GeoLocation | undefined {
    const data = image.technicalMetadata?.formData;
    if (data) {
      const location = {
        latitude: parseOptionalFloat(data.image_exif_GPSLatitude),
        longitude: parseOptionalFloat(data.image_exif_GPSLongitude),
        altitude: parseOptionalFloat(data.image_exif_GPSAltitude),
        accuracy: parseOptionalFloat(data.image_exif_GPSHPositioningError),
        direction: parseOptionalFloat(data.image_exif_GPSImgDirection),
        speed: parseOptionalFloat(data.image_exif_GPSSpeed),
      };

      if (isGeolocation(location)) {
        return location;
      }
    }
  }

  $: location = getLocation(image);

  const locationDescription = (location: GeoLocation) =>
    `${location.latitude.toFixed(5)}° N, ${location.longitude.toFixed(5)}° Ø`;

  function formatEquipment(image: Image) {
    const { image_exif_Make, image_exif_Model, image_exif_LensModel, image_exif_Lens } =
      image?.technicalMetadata?.formData || {};
    let camera: string[] = [];
    let lens: string = "";

    if (image_exif_Make) {
      camera.push(image_exif_Make.toLowerCase());
    }

    if (image_exif_Model) {
      camera.push(prettyModel[image_exif_Model] || image_exif_Model);
    }

    const lensOrModel = image_exif_LensModel || image_exif_Lens;

    if (lensOrModel) {
      lens = upperFirst(
        lensOrModel
          .replace(image_exif_Model ?? "", "")
          .replace(upperFirst(image_exif_Make?.toLowerCase()), "")
          .trim(),
      );

      // Hide that iPhones have cameras on both sides
      if (lens.includes("Back") && image_exif_Make?.match(/apple/i)) {
        lens = `${upperFirst(lens.split("Back").pop()?.split("mm").shift()).trim()}mm`;
      }
    }

    return {
      camera: camera.join(" "),
      lens,
    };
  }
</script>

<dl>
  {#if !(JSON.stringify(equipment) === "{}")}
    <dt>Kamera</dt>
    <dd class="flex">
      {#if equipment.camera !== undefined}<span title="Kamera">{equipment.camera}</span>{/if}
      {#if equipment.lens !== undefined}<span title="Linse">{equipment.lens}</span>{/if}
      {#if technical?.image_exif_SerialNumber}
        <span class="serialnumber" title="Serienummer">
          #{technical.image_exif_SerialNumber}
        </span>
      {/if}
    </dd>
  {/if}
  {#if !(JSON.stringify(exposure) === "{}")}
    <dt>Eksponering</dt>
    <dd class="flex">
      {#if exposure.iso}<span title="ISO-hastighet">ISO {exposure.iso}</span>{/if}
      {#if exposure.focalLength}<span title="Brennvidde">{exposure.focalLength}</span>{/if}
      {#if exposure.exposureValue}
        <span title="Eksponeringskompensasjon">
          {exposure.exposureValue} ev
        </span>
      {/if}
      {#if exposure.aperture}
        <span title="Blender">
          ƒ&nbsp;/&nbsp;{exposure.aperture.toLocaleString("en-US", {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          })}
        </span>
      {/if}
      {#if exposure.shutterSpeed}<span title="Lukkertid">{exposure.shutterSpeed} s</span>{/if}
      {#if exposure.flashFired}
        <span title="Blitz ({technical?.image_exif_Flash})" style="font-size: .8rem">
          {@html orgLightning}
        </span>
      {/if}
    </dd>
  {/if}
  {#if image.metadata.imageType}
    <dt>Bildetype</dt>
    <dd class="flex">
      <span>{image.metadata.imageType}</span>
    </dd>{/if}
  {#if location}
    <dt>Lokasjon</dt>
    <dd class="flex">
      <span>
        {@html orgMapMarker}
        <a
          href={`http://maps.apple.com/?ll=${location.latitude},${location.longitude}&q=Bildets%20plassering`}
          target="_blank"
        >
          {locationDescription(location)}
        </a>
        {#if location.accuracy}
          <span class="extra" style="margin-left: 6px" title="Nøyaktighet">
            ±{location.accuracy.toFixed(0)} m
          </span>
        {/if}
      </span>
      {#if location.altitude}
        <span class="extra" title="Høyde">{location.altitude.toFixed(0)} moh.</span>
      {/if}
      {#if location.speed}
        <span class="extra" title="Bevegelse">
          {location.speed > 1 ? location.speed.toFixed(0) : 0} km/t
        </span>
      {/if}
    </dd>
  {/if}
  {#if technical?.image_exif_Keywords}
    <dt>Stikkord</dt>
    <dd>
      {technical?.image_exif_Keywords}
    </dd>
  {/if}
</dl>

<style>
  a {
    color: unset;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  .serialnumber,
  .extra {
    opacity: 0;
    transition: 0.25s;
    color: var(--org-color-gray-400);
  }

  .serialnumber {
    font-family: monospace;
    font-size: 0.8rem;
  }

  dd:hover .serialnumber,
  dd:hover .extra {
    opacity: 1;
  }
</style>
